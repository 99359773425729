<template>
  <div class="order-card-container">

    <div class="flex">
      <div>
        <div class="order-number">
          Заказ №{{ props.order.id }}
        </div>
        <div class="order-date">
          {{ props.order.date }}
        </div>
        <div class="order-status">
          {{ props.order.status }}
        </div>
        <div class="order-cost">
          Стоимость покупки {{ props.order.cost }}
        </div>
      </div>
<!--      <div class="right-text middle-el" @click="hideItems">-->
<!--        <div v-if="props.order.type === 'consultation'">-->
<!--          <img src="../../assets/img/profile/message-icon.svg" />-->
<!--        </div>-->
<!--        <div v-else :class="{ 'rotation-part': isHided}">-->
<!--          <img src="../../assets/img/profile/arrow-down.svg" />-->
<!--        </div>-->


<!--      </div>-->

    </div>

    <div :class="{ 'expansion-body': true, 'expansion-hide': isHided }">
<!--      <div>-->
<!--        Самовывоз по адресу: {{ props.order.address }}-->
<!--      </div>-->
<!--      <div>-->
<!--        Времы работы пункта выдачи:-->
<!--        {{ props.order.times }}-->
<!--      </div>-->
      <div v-for="(item ,i) in order.items" :key="i">
        <ProductCardBasketElement :show-heart="false" :product="item" :can-change-count="false"/>
      </div>

<!--      <button class="order-return-btn standart-size-btn">-->
<!--        Вернуть заказ-->
<!--      </button>-->
    </div>


  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import ProductCardBasketElement from "@/elements/Cards/ProductCardBasketElement";

const props = defineProps(['order'])


const isHided = ref(false)

// const hideItems = () => {
//   if(props.order.type!=="consultation"){
//     isHided.value = !isHided.value
//   }
//
// }

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.middle-el{
  margin-top: auto;
  margin-bottom: auto;
}

.order-return-btn{
  background-color: white;
  border: none;
  color: #9EADC4;


  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;

}

.order-card-container{
  margin: 2px;
  text-align: left;

  box-shadow: 0px 0px 4px 1px rgba(34, 60, 80, 0.2);
  border-radius: 5px;
  padding: 5px;
}

.rotation-part {
  transform: rotate(180deg);
  transition: 1s;
}

.expansion-hide {
  max-height: 0 !important;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1) !important;

}

.expansion-body {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}

.order-date {

  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

}

.order-status {

  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

}

.order-cost {

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.order-number {

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

</style>