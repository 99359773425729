<template>
  <div class="container">
    <HeaderWithBackIconElement
        back-link="/order/pickup" heading="Оплата заказа"/>

    <InputElement v-model="form.email" label="Чек придет на почту"/>



    <div class="app-grey-text">
      СПОСОБ ОПЛАТЫ
    </div>

    <SelectableBtnGroupElement
        :buttons-data="buttonsData"
        :selected="'samsung'"
        :selected-changed="radioBtnChanged" />

    <div class="flex app-bold-text">
      <div>
        Стоимость к оплате
      </div>
      <div class="right-text">
        {{ store.state.orderDetails.summaryCost }} ₽
      </div>
    </div>

    <div class="app-grey-text">
      Нажимая оплатить, Вы соглашаетесь с
      <router-link to="">
        договором оферты
      </router-link>

    </div>

    <button
        @click="pay"
        class="app-blue-btn standart-size-btn">
      <div style="margin: auto;">
        Оплатить заказ
      </div>

    </button>

  </div>
</template>

<script setup>
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import {reactive} from "vue";
import store from "@/store";
import SelectableBtnGroupElement from "@/elements/Buttons/SelectableBtnGroupElement";

const buttonsData = [
  {
    key: 'courier',
    text: 'Наличными курьеру',
    iconUrl: ''
  },
  {
    key: 'market',
    text: 'Оплатить в магазине',
    iconUrl: ''
  },
  {
    key: 'online',
    text: 'Оплатить картой онлайн',
    iconUrl: ''
  },
  {
    key: 'google',
    text: 'Google Pay',
    iconUrl: '/icons/google-pay-mark.svg'
  },
  {
    key: 'samsung',
    text: 'Samsung Pay',
    iconUrl: '/icons/samsung-pay-mark.svg'
  },
]

const radioBtnChanged = (val)=>{
  console.log(val)
}

const form = reactive({
  email: '',
})

const pay = () => {
  console.log('payed?')
}

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.google-pay-btn {
  border: none;
  background-color: black;
  color: white;
}


a {
  color: inherit;
}

.container {
  padding: 10px;
}

</style>