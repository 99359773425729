<template>
  <div>
    <div class="header-container">
      <div class="close-icon">
        <router-link :to="'/catalog_section/' + props.section">
          <img src="/icons/close.svg"/>
        </router-link>
      </div>
      <div class="heading">
        Фильтры
      </div>
    </div>

    <div class="filters-container">
      <div>
        <div class="heading-label">
          СОРТИРОВАТЬ ПО
        </div>
        <div class="flex">
          <div>
            <button
                class="app-blue-btn"
                v-if="filters.order_by_asc">
              Убыванию цены
            </button>
            <button
                class="app-white-btn"
                v-else
                @click="()=>switchPriceOrder()">
              Убыванию цены
            </button>
          </div>
          <div>
            <button
                class="app-blue-btn"
                v-if="!filters.order_by_asc">
              Возрастанию цены
            </button>
            <button
                class="app-white-btn"
                v-else
                @click="()=>switchPriceOrder()">
              Возрастанию цены
            </button>
          </div>

        </div>
      </div>

      <div class="heading-label">
        СТИЛЬ
      </div>
      <div class="flex">
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.style.loft"
              @click="()=>changeStyle('loft')">
            Лофт &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeStyle('loft')">
            Лофт
          </button>
        </div>
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.style.minimalism"
              @click="()=>changeStyle('minimalism')">
            Минимализм &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeStyle('minimalism')">
            Минимализм
          </button>
        </div>
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.style.hai_tek"
              @click="()=>changeStyle('hai_tek')">
            Хай-тек &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeStyle('hai_tek')">
            Хай-тек
          </button>
        </div>

      </div>

      <div class="heading-label">
        ЦВЕТ
      </div>
      <div class="flex">
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.color.red"
              @click="()=>changeColor('red')">
            Красный &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeColor('red')">
            Красный
          </button>
        </div>
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.color.yellow"
              @click="()=>changeColor('yellow')">
            Желтый &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeColor('yellow')">
            Желтый
          </button>
        </div>
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.color.grey"
              @click="()=>changeColor('grey')">
            Серый &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeColor('grey')">
            Серый
          </button>
        </div>

        <div>
          <button
              class="app-blue-btn"
              v-if="filters.color.black"
              @click="()=>changeColor('black')">
            Черный &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeColor('black')">
            Черный
          </button>
        </div>
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.color.blue"
              @click="()=>changeColor('blue')">
            Синий &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeColor('blue')">
            Синий
          </button>
        </div>
        <div>
          <button
              class="app-blue-btn"
              v-if="filters.color.turquoise"
              @click="()=>changeColor('turquoise')">
            Бирюзовый &nbsp;
            <img src="/icons/close_circled.svg"/>
          </button>
          <button
              class="app-white-btn"
              v-else
              @click="()=>changeColor('turquoise')">
            Бирюзовый
          </button>
        </div>
      </div>

      <div>
        <div class="heading-label">
          ЦЕНА, РУБ
        </div>
        <RangeInputElement
            :min="1990" :max="204000"
            :min-value="filters.minPrice" :max-value="filters.maxPrice"
            :changed="priceRangeChanged"/>
      </div>
      <div>
        <div class="heading-label">
          ШИРИНА, СМ
        </div>
        <RangeInputElement
            :min="35" :max="3500"
            :min-value="filters.minWidth" :max-value="filters.maxWidth"
            :changed="widthRangeChanged"/>
      </div>
      <div>
        <div class="heading-label">
          ГЛУБИНА, СМ
        </div>
        <RangeInputElement
            :min="45" :max="1500"
            :min-value="filters.minDepth" :max-value="filters.maxDepth"
            :changed="deepRangeChanged"/>
      </div>

    </div>


    <BottomFixedPanelElement height="70">
      <button class="app-blue-btn apply-btn" @click="applyChanges">
        Применить
      </button>
    </BottomFixedPanelElement>

  </div>
</template>

<script setup>
import {reactive, defineProps} from "vue";
import store from "@/store";
import router from "@/router";
import RangeInputElement from "@/elements/Inputs/RangeInputElement"
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement"

console.log('init filters component')

const props = defineProps(['section'])

const filters = reactive({})
Object.assign(filters, store.state.search_filters)

console.log(store.state.search_filters)
console.log(filters)

const switchPriceOrder = () => {
  filters.order_by_asc = !filters.order_by_asc
}


const changeStyle = (name) => {
  filters.style[name] = !filters.style[name]
}

const changeColor = (name) => {
  filters.color[name] = !filters.color[name]
}

const priceRangeChanged = (vals) => {
  filters.minPrice = vals[0]
  filters.maxPrice = vals[1]
}

const widthRangeChanged = (vals) => {
  filters.minWidth = vals[0]
  filters.maxWidth = vals[1]
}

const deepRangeChanged = (vals) => {
  filters.minDepth = vals[0]
  filters.maxDepth = vals[1]
}


const applyChanges = () => {
  store.commit('updateFilters', filters)

  router.push('/catalog_section/'+props.section)
}


</script>

<style src="../../assets/styles/buttons.css"></style>
<style scoped>

.apply-btn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 345px;
  height: 40px;
}

.app-blue-btn {
  margin-right: 5px;
}

.app-white-btn {
  margin-right: 5px;
}

.filters-container {
  margin: 5px;
}

.heading-label {
  font-family: Open Sans, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  color: #757577;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.heading {
  font-family: Roboto, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;

  margin: auto;
}

.header-container {
  display: flex;
  height: 44px;

}

.close-icon {
  margin-top: 14px;
  margin-left: 14px;
}

</style>