<template>
  <div class="app-content-container">


    <div v-for="(order, i) in props.orders"  :key="i">
      <OrderCardElement :order="order" />
    </div>



  </div>
</template>

<script setup>
import {defineProps} from "vue";
import OrderCardElement from "@/elements/Cards/OrderCardElement";

const props = defineProps(['orders'])

console.log(props)







</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

</style>