<template>
  <div class="container">
    <div class="text">
      Все возможности в мобильном приложении
    </div>
    <div class="markets-container">
      <a href="https://apple.co/3wVaVR9">
        <img style="margin-right: 20px;" src="@/assets/img/appStore.svg"/>
      </a>
      <a href="https://play.google.com/store/apps/details?id=ru.cdoglobal.neuraldesigner">
        <img src="@/assets/img/playMarket.svg"/>
      </a>


    </div>
    <div class="text">
      Если у Вас есть вопросы и предложения, напишите нам через
      <a href="#">форму</a>
      или на email:
      <a href="#">email@email.com</a>
    </div>
    <div class="bottom-text">
      <a href="#">
        © Нейродизайнер 2021
      </a>
      <a href="https://docs.google.com/document/d/1Uvyq81pL_N_oeyw-odm9fZ7Yi3OVPhJt/edit?usp=sharing&ouid=103247238221723101111&rtpof=true&sd=true">
        Политика конфиденциальности
      </a>
      <a href="https://docs.google.com/document/d/1n7uAi6gENRTUxzmkD_GCPAyQA-sQSH2u/edit?usp=sharing&ouid=103247238221723101111&rtpof=true&sd=true">
        Пользовательское соглашение
      </a>
    </div>
  </div>
</template>


<style scoped>

.bottom-text{
  margin-top: 20px;
  margin-bottom: 20px;
}

.bottom-text a{
  color: #FFFFFF;
  font-family: Open Sans,serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px;
}

a {
  text-align: center;
  font-family: Open Sans, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.container {
  background-color: #9EADC4;
  height: 300px;
  padding-top: 40px;
  text-align: center;
}

.markets-container {
  margin: 40px auto 55px;
}

.text {
  text-align: center;
  font-family: Open Sans, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

</style>