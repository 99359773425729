const imgs = [
    'https://catherineasquithgallery.com/uploads/posts/2021-02/1612503153_91-p-koshka-na-serom-fone-134.jpg',
    'https://www.wallpaperflare.com/static/341/974/440/kitten-grass-face-furry-wallpaper.jpg',
    'https://catherineasquithgallery.com/uploads/posts/2021-02/1612503153_91-p-koshka-na-serom-fone-134.jpg'
]


export default {

    products: [
        {
            weight: 1.3,
            width: 100,
            height: 121,
            deep: 59,
            imgUrls: imgs,
            userCategory: 'Диваны',
            countAvailable: 4,
            addingCount: 0,
            price: 100,
            oldPrice: 80,
            id: 1,
            name: 'gas',
            description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
        },
        {
            weight: 1.3,
            width: 100,
            height: 121,
            deep: 59,
            userCategory: 'Диваны',
            countAvailable: 4,
            addingCount: 0,
            price: 100,
            oldPrice: 80,
            id: 2,
            name: 'ssas2',
            description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
            imgUrls: imgs
        },
        {
            weight: 1.1,
            width: 100,
            height: 121,
            deep: 59,
            userCategory: 'Диваны',
            countAvailable: 4,
            addingCount: 0,
            price: 103,
            oldPrice: 80,
            id: 3,
            name: 'das',
            description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
            imgUrls: imgs
        },
        {
            weight: 1.5,
            width: 100,
            height: 121,
            deep: 59,
            userCategory: '',
            countAvailable: 4,
            addingCount: 0,
            price: 105,
            oldPrice: 80,
            id: 4,
            name: 'dsas',
            description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
            imgUrls: imgs
        },
        {
            weight: 1.6,
            width: 100,
            height: 121,
            deep: 59,
            userCategory: 'Стены',
            countAvailable: 4,
            addingCount: 0,
            price: 150,
            oldPrice: 80,
            id: 5,
            name: 'sass',
            description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
            imgUrls: imgs
        },


    ]
}


