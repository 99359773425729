<template>
  <div>
    <HeaderWithBackIconElement back-link="/profile/message_designer" heading="Консультация оплачена" />

    <div class="app-content-container">
      <p>
        Теперь вы можете начать чат с дизайнером и
        задать интересующие вас вопросы.
        Вы также можете начать чат с дизайнером позже,
        просто перейдите к списку дизайнеров из Профиля
      </p>
      <router-link :to="'/profile/consultation/chat/'+props.memberId">
        <button class="standart-size-btn app-white-btn">
          Начать чат
        </button>
      </router-link>

    </div>

    <BottomFixedPanelElement height="60">
      <BottomMenuElement />
    </BottomFixedPanelElement>
  </div>
</template>

<script setup>
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement";
import BottomMenuElement from "@/elements/BottomMenuElement";
import {defineProps} from "vue";

const props = defineProps(["id", "memberId"])

console.log(props.id)

</script>

<style scoped>

a{
  text-decoration: none;
  color: black;
}

</style>