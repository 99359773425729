export default {
    tabImgNames: {
        ar: 'ar',
        favourite: 'heart',
        basket: 'basket',
        catalog: 'catalog',
        profile: 'profile'
    },

    apiUrl: "https://api.neurodesigner.ru"

}