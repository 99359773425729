<template>


  <div class="form-component-container">
    <h2 class="heading password-recovery-heading">
      Восстановление пароля
    </h2>
    <InputElement v-model="email" label="Email, который Вы использовали при регистрации"/>
    <button @click="sendEmail" class="app-blue-btn auth-btn">
      Отправить
    </button>


    <button class="app-white-btn register-link auth-btn">
      <router-link to="/register">Я помню пароль</router-link>
    </button>
  </div>

</template>

<script setup>
import InputElement from "@/elements/Inputs/InputElement";
import {ref} from "vue";
import httpClient from "@/httpClient/httpClient";
import store from "@/store";
import router from "@/router";

const email = ref('');

const sendEmail = async () => {
  let res = await httpClient.Post('/auth/resetPassword', {
    email: email.value
  })
  store.commit('setEmail',email.value)
  console.log(res)
  console.log(store.state.email)
  await router.push('/password_recovery_code#email='+email.value)
}

</script>

<style src="@/assets/styles/all.css"></style>

<style scoped>
.password-recovery-heading {
  height: 40px;
  font-family: Montserrat,serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;


}
</style>