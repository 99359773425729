<template>
  <div>
    <input v-model="url" />
    <div v-for="(path, i) in paths" :key="i">
      <router-link :to="path">
        {{path}}
      </router-link>
    </div>

  </div>
</template>

<script setup>
import router from "@/router";
import {computed, ref} from 'vue'

const url = ref('')

const paths = computed(()=> router.getRoutes().map(x=>x.path).filter(x=>x.includes(url.value)))





</script>

<style scoped>
div{
  padding: 10px;
  font-size: 40px;
}
</style>