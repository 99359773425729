<template>
  <HeaderWithBackIconElement style="margin-bottom: 10px;" heading="Оплата консультации" back-link="/profile"/>
  <div class="app-content-container">
    <InputElement label="Чек придет на почту"/>


    <div class="number-control-container">
      <div class="number-input-label">
        Часов консультации
      </div>
      <div class="number-input-container">
        <div @click="minusHour" class="middle ">
          <img src="/icons/minus.svg" />
        </div>
        <div class="center middle">
          {{hours}}
        </div>
        <div @click="plusHour" class="middle ">
          <img src="/icons/plus.svg" />
        </div>
      </div>

    </div>



    <div class="flex app-bold-text">
      <div>
        Стоимость к оплате
      </div>
      <div class="right-text">
        {{props.designer.servicePrice * hours}} ₽
      </div>
    </div>

    <div class="app-grey-text">
      Нажимая оплатить, Вы соглашаетесь с
      <router-link to="">
        договором оферты
      </router-link>

    </div>



    <button class="app-blue-btn auth-btn center" @click="createPurchase">
      Перейти к оплате
    </button>

  </div>
</template>

<script setup>
//import {defineProps} from "vue";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import purchaseService from "@/services/purchaseService";
import {ref, defineProps} from "vue";
import router from "@/router";

const props = defineProps(['designer'])


const hours = ref(0)

const minusHour = ()=>{
  if(hours.value>0){
    hours.value -= 1
  }

}

const plusHour = ()=>{
  hours.value += 1
}

function CreateUUID() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

const inProcess = ref(false)

//TODO: design better + add logic
const createPurchase = ()=>{
  if(!inProcess.value){
    inProcess.value = true
    let idempotenceKey = CreateUUID()
    purchaseService.CreateConsultationPurchase({
      designerEmail: props.designer.login,
      numberOfHours: hours.value,
      idempotenceKey: idempotenceKey
    }).then(res=>{
      console.log("CreateConsultationPurchase", res)

      window.open(res.purchaseUrl, '_blank').focus()
      router.push("/profile/consultation/payed/"+idempotenceKey+"/"+props.designer.userId)
    })
  }else{
    alert("wait")
  }


}

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.google-pay-btn {
  border: none;
  background-color: black;
  color: white;
}


a {
  color: inherit;
}


.number-input-label {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.number-input-container {
  border: 1px solid black;
  display: flex;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.number-control-container {
  margin-top: 10px;
}

</style>