<template>
  <div class="menu">
    <div v-for="(tab, i) in tabs" :key="i" class="menu-item">
      <router-link :to="tab.link">
        <div>
          <div class="icon-container">
            <img :src="tab.imgUrl" />
          </div>
          <div class="label">
            {{tab.label}}
          </div>
        </div>

      </router-link>
    </div>
  </div>
</template>

<script setup>
import {reactive, defineProps} from "vue";

const props = defineProps(['activeTabName'])

const iconNameList = [
  {img: 'catalog', link: '/catalog', label: 'Каталог'},
  {img: 'heart', link: '/favourites', label: 'Избранное'},
  {img:'ar', link: '/ar', label: 'AR'} ,
  {img: 'basket', link: '/basket', label: 'Корзина'},
  {img: 'profile', link: '/profile', label: 'Профиль'}
]

const tabs = reactive([])

const calcUrls = () => {
  iconNameList.forEach((tab) => {
    props.activeTabName === tab.img ?
        tabs.push({imgUrl: '/menu_icons/' + tab.img + '_disabled.svg', link: tab.link, label: tab.label}) :
        tabs.push({imgUrl: '/menu_icons/' + tab.img + '.svg', link: tab.link, label: tab.label})
  })
}

calcUrls()


</script>

<style scoped>

.label{
  color: black;
}

.icon-container{
  height: 27px;
}

.menu-item{
  margin-top: 8px;
}

a{
  text-decoration: none;
}

.menu {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

</style>