<template>
  <DesignerProfileComponent v-if="designer!==null" :designer="designer"/>
  <div v-else>
    идет загрузка...
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DesignerProfileComponent from "@/components/Profile/DesignerProfileComponent";
import designerService from "@/services/designerService";

const props = defineProps(['id'])

console.log('designer id: ', props.id)

const designer = ref(null)

designerService.GetSelf().then(res=>{
  console.log("GetDesigner", res)
})

designerService.GetDesigner(props.id).then(res=>{
  console.log("GetDesigner", res)
  designer.value = res.designer
})


</script>

<style scoped>

</style>