<template>
  <div class="container">
    <div>
      <router-link :to="props.backLink">
        <img class="icon" :src="iconUrl" />
      </router-link>
    </div>
    <div class="heading">
      {{props.heading}}
    </div>
    <div style="visibility: hidden;">
      <router-link :to="props.backLink">
        <img class="icon" :src="iconUrl" />
      </router-link>
    </div>
  </div>
</template>

<script setup>
import {defineProps,ref} from "vue";

const props = defineProps(['backLink', "heading" , 'iconUrl'])

const iconUrl = ref(props.iconUrl)

if (props.iconUrl === null || props.iconUrl === undefined) {
  iconUrl.value = '/icons/left-arrow.svg'
}


</script>

<style scoped>

.heading{
  margin: auto;

  font-family: Open Sans;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

}

.icon{
  margin-top: 12px;
  margin-left: 10px;

  max-height: 20px;
}

.container {
  display: flex;
  height: 44px;

}

</style>