<template>
  <div v-if="groups!==null">
    <FavouriteCategoriesComponent :groups="groups" />
  </div>
  <div v-else>
    loading please wait ...
  </div>
  <BottomFixedPanelElement height="260">
    <BottomMenuElement active-tab-name="heart" />
  </BottomFixedPanelElement>
</template>

<script setup>
// import store from "@/store";
// import exampleTestModels from "@/test/exampleTestModels";

import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement"
import BottomMenuElement from "@/elements/BottomMenuElement"
import FavouriteCategoriesComponent from "@/components/Favourite/FavouriteCategoriesComponent";
import {ref} from "vue";
import favouritesService from "@/services/favouritesService";

// store.commit('setFavouriteProducts',
//                   exampleTestModels.products)

const groups = ref(null)

favouritesService.GetFavouriteGroups().then(data=>{
  console.log("fv groups data loaded - ", data)
  groups.value = data
})



</script>

<style scoped>

</style>