<template>
  <div class="app-content-container" style="">
    <div class="header-content-container">
      <router-link to="/profile/message_designer">
        <div class="back-icon">
          <img class="back-icon-margin" src="/icons/left-arrow.svg"/>
        </div>
      </router-link>
      <div class="middle">
        <img class="designer-avatar" :src="downloadPath + props.designer.avatarFileId"/>
      </div>
      <router-link :to="'/profile/consultation/chat/'+props.designer.userId">
        <div class="msg-icon">
          <img class="msg-icon-margin" src="../../assets/img/profile/message-icon.svg"/>
        </div>
      </router-link>
    </div>
    <div class="center">
      <div class="designer-name small-margins">
        {{ props.designer.fullName }}
      </div>
      <div class="maximum-centered">
        <div class="flex designer-stage small-margins center">
          <div class="middle small-right-margin">
            <img src="../../assets/img/profile/star.svg"/>
          </div>
          <div class="middle small-right-margin">
            {{ props.designer.rating }}
          </div>
          <div class="middle">
            Стаж {{ props.designer.experience }} лет
          </div>
        </div>
      </div>

      <div class="small-margins">
        Консультация {{ props.designer.servicePrice }} ₽/час
      </div>
    </div>
    <div class="workExamplesContainer">
      <div v-for="(workId, i) in props.designer.workIds" :key="i">
        <div v-if="i%2===0">
          <img class="workExampleImg" :src="downloadPath+workId" />
        </div>
        <div v-else>
          <img class="workExampleImg right-text" :src="downloadPath+workId" />
        </div>
      </div>
    </div>



  </div>
  <BottomFixedPanelElement height="60">
    <button @click="goToOrder" class="app-blue-btn auth-btn center">
      Консультация {{props.designer.servicePrice}} ₽/час
    </button>
  </BottomFixedPanelElement>
</template>

<script setup>
import {defineProps} from "vue";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement";
import router from "@/router";
import constants from "@/constants/constants";



const downloadPath = constants.apiUrl + "/userFile/download?id="

const props = defineProps(['designer'])

const goToOrder = ()=>{
  router.push("/profile/designer/payment/"+props.designer.userId)
}


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.designer-name{

  font-family: Open Sans;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

}

.workExamplesContainer{
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.workExampleImg{
  width: 180px;
  height: 180px;
}


.maximum-centered {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.back-icon {
  margin-top: 5px;
  margin-left: 5px;
  height: 40px;
  width: 40px;
  margin-right: auto;
  box-shadow: 0px 0px 2px 1px rgba(34, 60, 80, 0.2);

}

.back-icon-margin {
  margin-top: 10px;

}

.msg-icon {
  margin-top: 5px;
  margin-right: 5px;
  height: 40px;
  width: 40px;
  margin-left: auto;
  box-shadow: 0px 0px 2px 1px rgba(34, 60, 80, 0.2);

}

.msg-icon-margin{
  margin-top: 8px;
}

.header-content-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}

.avatar-container {
  margin-right: 10px;
}

.small-margins {
  margin-top: 3px;
  margin-bottom: 3px;
}

.small-right-margin {
  margin-right: 3px;
}

.designer-stage {
  height: 25px;

  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

}

.designer-avatar {
  width: 165px;
  height: 165px;
}

</style>