<template>
  <div class="container" @click="goToProductPage">

<!--    <div class="discount">-->
<!--      {{ Math.round(((product.price - product.oldPrice) / product.oldPrice) * 1000) / 10 }}%-->
<!--    </div>-->
    <div
        onclick="event.stopPropagation()"
        v-if="showHeart"
        class="like-icon"
        @click="addRemoveFromFavourite">
      <img :src="product.liked === true ? '/icons/heart_filled.svg' : '/icons/heart.svg' "/>
    </div>
    <CarouselElement :img-urls="product.imgUrls"/>
    <div style="height: 10px;"></div>
    <div class="price-container">
      <div class="price">
        {{ product.price }} ₽
      </div>
      <div class="old-price">
        {{ product.oldPrice }} ₽
      </div>
    </div>
    <div class="product-name">
      {{ product.name }}
    </div>
  </div>
</template>

<script setup>
import {reactive, defineProps, ref} from "vue";
// import {isProductInFavourites} from "@/actions/storeActions";
// import store from "@/store";
import CarouselElement from '@/elements/Carousels/CarouselElement'
//import router from "@/router";
//import productService from "@/services/productService";
import router from "@/router";
import favouritesService from "@/services/favouritesService";

const props = defineProps({
  product: Object,
  showHeart: Boolean,
})

const goToProductPage = () => {
  router.push('/product/'+props.product.id+"/"+props.product.currentColor)
}

//const inFavourites = ref(isProductInFavourites(props.product.id))

const product = reactive(props.product)

const showHeart = ref(true)

// I just don't believe javascript
if (props.showHeart === false) {
  showHeart.value = false
}

const addRemoveFromFavourite = async () => {

  if (product.liked) {
    let res = await favouritesService.RemoveFromFavourite(product.id, product.currentColor)
    console.log("dislike res")
    console.log(res)
  } else {
    let res = await favouritesService.AddToFavourite(product.id, product.currentColor)
    console.log("like res")
    console.log(res)
  }

  product.liked = !product.liked

}

// const addToFavourite = () => {
//   store.commit('addToFavourite', product)
//   inFavourites.value = true
// }
//
// const removeFromFavourite = () => {
//   store.commit('removeFromFavourite', product.id)
//   inFavourites.value = false
// }

</script>


<style scoped>

.discount {
  position: absolute;
  z-index: 9999;
  background-color: #9EADC4;
  margin: 5px;

  color: #FFFFFF;
}


.like-icon {
  position: relative;
  z-index: 9998;
  margin-top: 5px;
  margin-left: 135px;

  color: #FFFFFF;

  cursor: pointer;

  top: 10px;
  right: 10px;
}

.container {
  width: 165px;
  background-color: #EFEFEF;
}

.product-name {
  font-family: Open Sans, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  color: #000000;
}

.old-price {
  height: 16px;
  width: 49px;
  font-family: Open Sans, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  color: #757577;

  text-decoration-line: line-through;

}

.price {
  height: 20px;
  width: 64px;
  font-family: Open Sans, serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #000000;

}

.price-container {
  display: flex;

}

</style>