<template>
  <div class="form-component-container">
    <h2 class="heading">
      Восстановление пароля
    </h2>
    <div>
      Придумайте новый пароль, не короче 8 символов
    </div>
    <div>
      <InputElement
          class="email"
          v-model="password"
          label="Придумайте пароль"
          type="password"/>
    </div>
    <div>
      <InputElement
          v-model="passwordRepeat"
          label="Повторите пароль"
          type="password"/>
    </div>

    <button @click="savePassword" class="app-blue-btn auth-btn">
      Сохранить
    </button>


  </div>
</template>

<script setup>
import InputElement from "@/elements/Inputs/InputElement";
import {ref} from "vue";
import httpClient from "@/httpClient/httpClient";
import store from "@/store";
import router from "@/router";
//import router from "@/router";

const password = ref('')
const passwordRepeat = ref('')

const savePassword = async () => {
  let user = store.getters.getUser

  let json_data = {
    email: user.email,
    password: password.value,
    code: user.code
  }

  console.log(json_data)

  let res = await httpClient.Post('/Auth/confirmResetPassword', json_data)

  console.log(res)

  if(res.status === 'Ok'){
    await router.push('/auth')
  }else{
    alert('smthng wrong')
  }
}


</script>

<style src="@/assets/styles/all.css"></style>
<style scoped>

</style>