<template>
  <div class="app-content-container padding10">
    <div class="app-center-header">
      Профиль
    </div>
    <div v-if="isAuthorized">

      <div class="user-profile-container">
        <div class="user-profile">
          <div>
            <img v-if="avatarLink===null" class="user-avatar" src="../../assets/img/profile/avatar.png" />
            <img v-else class="user-avatar" :src="avatarLink" />
          </div>
          <div class="middle user-greeting">
            Привет, {{userName}}
          </div>
        </div>
      </div>

      <router-link :to="profileLink">
        <div class="link-el">
          <div class="middle icon">
            <img src="../../assets/img/profile/user-icon.svg" />
          </div>
          <div class="middle">
            Персональные данные
          </div>

        </div>
      </router-link>
      <router-link to="/profile/orders">
        <div class="link-el">
          <div class="middle icon">
            <img src="../../assets/img/profile/basket-icon.svg" />
          </div>
          <div class="middle">
            Мои заказы
          </div>

        </div>
      </router-link>
      <router-link to="/profile/message_designer">
        <div class="link-el">
          <div class="middle icon">
            <img src="../../assets/img/profile/computer-icon.svg" />
          </div>
          <div class="middle">
            Написать дизайнеру
          </div>

        </div>
      </router-link>
      <router-link to="/profile/product_about">
        <div class="link-el">
          <div class="middle icon">
            <img src="../../assets/img/profile/info-icon.svg" />
          </div>
          <div class="middle">
            О продукте
          </div>
        </div>
      </router-link>
      <router-link to="/profile/help">
        <div class="link-el">
          <div class="middle icon">
            <img src="../../assets/img/profile/question-mark.svg" />
          </div>
          <div class="middle">
            Помощь
          </div>
        </div>
      </router-link>

      <div @click="exit" class="link-el">
        <div class="middle icon">
          <img src="../../assets/img/profile/exit-icon.svg" />
        </div>
        <div class="middle">
          Выход
        </div>
      </div>


    </div>
    <div v-else>

      <div class="link-el">
        <div class="middle icon">
          <img src="../../assets/img/profile/geo-point.svg" />
        </div>
        <div class="middle">
          Санкт-Петербург
        </div>

      </div>
      <div class="link-el">
        <div class="middle icon">
          <img src="../../assets/img/profile/question-mark.svg" />
        </div>
        <div class="middle">
          Помощь
        </div>
      </div>


      <div class="auth-container">
        <div>
          Авторизируйтесь, для просмотра заказов и составления списков избранного
        </div>
        <router-link to="/auth">
          <button class="standart-size-btn app-white-btn">
            Войти
          </button>
        </router-link>

      </div>


    </div>

  </div>
  <BottomFixedPanelElement height="60">
    <BottomMenuElement active-tab-name="profile" />
  </BottomFixedPanelElement>
</template>

<script setup>
import store from "@/store";
import {ref} from "vue";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement";
import BottomMenuElement from "@/elements/BottomMenuElement";
import userService from "@/services/userService";


const isAuthorized = ref(store.state.user.token!=='')

const profileLink = ref('/profile/personal')

const avatarLink = ref(null)

const exit = ()=>{
  store.commit('setToken', '')
  isAuthorized.value = false
}

const userName = ref("Человек")

userService.GetUserData().then(res=>{
  userName.value = res.name
  if(res.avatarId!==null){
    avatarLink.value = 'https://api.neurodesigner.ru/userfile/download?id='+res.avatarId
  }
})



</script>

<style scoped>

a{
  text-decoration: none;
  color: black;
}

.auth-container{
  margin-top: 50px;

  font-family: Open Sans,serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;


}

.user-greeting{
  margin-left: 10px;

  font-family: Roboto,serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

}

.user-avatar{
  width: 75px;
  height: 75px;
}

.user-profile-container{
  height: 105px;

}

.user-profile{
  margin-top: auto;
  margin-bottom: auto;

  display: flex;

}

.icon{
  margin-right: 5px;
}

.middle{
  margin-top: auto;
  margin-bottom: auto;
}

.link-el{
  height: 45px;
  display: flex;
  font-family: Open Sans;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

</style>