<template>
  <div class="container">
    <div>
      <img class="product-img" :src="props.product.imgUrls[0]"/>
    </div>
    <div>
      <div>
        {{ props.product.name }}
      </div>
      <div>
        {{ props.product.price }} ₽
      </div>
    </div>
    <div>
      <input v-model="checkbox" type="checkbox"/>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";

const props = defineProps(['product', 'change', 'value'])



const checkbox = ref(false)

const change = () => {
  if (props.change !== null && props.change !== undefined) {
    props.change(checkbox.value, props.product.id, props.product.currentColor)
  }
}

// eslint-disable-next-line no-unused-vars
watch(checkbox, async (newValue, oldValue) => {
  change(newValue)
})

console.log(props.value)
// I just don't believe javascript
if (props.value === true) {
  checkbox.value = true
}


</script>

<style scoped>

.product-img {
  width: 75px;
  height: 75px;
}

.container {
  display: grid;
  grid-template-columns: 80px 1fr 23px;
}


</style>