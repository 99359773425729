import httpClient from "@/httpClient/httpClient";
import httpHelper from "@/helpers/httpHelper";
import productMapper from "@/mappers/productMapper";

export default {

    GetAllProducts: async () => {
        return productMapper.mapAll(await httpClient.Get('/Product/GetAll'))
    },

    GetProducts: async () => {

        let query = httpHelper.GetSearchFiltersQuery()

        let res = await httpClient.Get('/Product/GetAllByParams?' + query)

        return productMapper.mapAll(res.products)
    },

    GetProduct: async (id) => {
        let data = await httpClient.Get('/Product/GetById?id=' + id)
        let product = productMapper.map(data.product)
        product.similarProducts = data.similarProducts

        return product
    },


}