import httpClient from "@/httpClient/httpClient";
import productMapper from "@/mappers/productMapper";

export default {


    AddToBasket: async (id, color) => {
        return await httpClient.Post("/Basket/AddItem?id=" + id + "&color=" + color, {})
    },

    RemoveFromBasket: async (id, color) => {
        return await httpClient.Post("/Basket/RemoveItem?id=" + id + "&color=" + color, {})
    },

    GetBasketProducts: async () => {
        return productMapper.mapAll((await httpClient.Get("/Basket/GetBasketItems")).items)
    },

    CreateOrder: async (order) => {
        return await httpClient.Post("/OrderFrom/Create", {
            city: order.city,
            street: order.str,
            house: order.house,
            stage: order.floor,
            email: order.email,
            firstName: order.firstName,
            lastName: order.lastName,
            comment: order.comment,
            address: order.city + ", улица " + order.street + " " + order.house,
        })
    }


}