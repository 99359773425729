<template>
  <h1
      v-if="store.state.config.isMobile===false"
      style="text-align: left;margin-left: 30%;margin-bottom: 80px;">
    ЛОГОТИП
  </h1>
  <AuthorizationComponent style="margin: auto;" />
  <div class="divider"></div>
  <FooterComponent v-if="store.state.config.isMobile===false" />
</template>

<script setup>
import AuthorizationComponent from "@/components/Authorization/AuthorizationComponent"
import FooterComponent from "@/components/FooterComponent"
import store from "@/store";

console.log(store.state.config)

</script>

<style scoped>

.divider{
  height: 120px;
}

</style>