<template>
  <div class="form-component-container">
    <h2 class="heading">
      Код подтверждения
    </h2>
    <div class="letter">
      На Ваш email отправлено письмо с кодом для сброса пароля. Введите этот код ниже
    </div>
    <div>
      <InputElement
          class="email"
          v-model="code"
          :label="'Код для сброса пароля'"/>
    </div>


    <button @click="sendCode" class="app-blue-btn auth-btn">
      Отправить
    </button>


    <button @click="resendCode" class="app-white-btn auth-btn register-link">
      Выслать код повторно
    </button>

    <div class="divider"></div>

  </div>
</template>

<script setup>
import {ref} from "vue";
import InputElement from "@/elements/Inputs/InputElement";
import store from "@/store";
import httpClient from "@/httpClient/httpClient";
import router from "@/router";

const code = ref('');

const resendCode = async ()=>{
  let user = store.getters.getUser
  let res = await httpClient.Post('/auth/resetPassword', {
    email: user.email
  })

  console.log(res)
  console.log(store.state.email)
}

const sendCode = async () => {

  let user = store.getters.getUser
  console.log(user)

  let res = await httpClient.Post('/Auth/checkResetPasswordCode', {
    code: code.value,
    email: user.email
  })
  console.log(res)

  if(res.status === 'Ok'){
    store.commit('setCode', code.value)
    await router.push('/password_recovery_password')
  }else {
    alert('invalid code')
  }

}

</script>

<style src="@/assets/styles/all.css"></style>
<style scoped>
.letter{
  font-family: Open Sans,serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.divider-mini{
  height: 30px;
}

.divider{
  height: 160px;
}

</style>