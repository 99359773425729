<template>
  <div>
    <ProductImagesComponent :product="productRef" :color="props.color" />
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import ProductImagesComponent from "@/components/Catalog/ProductImagesComponent"
import productService from "@/services/productService";

const props = defineProps(['id', 'color'])

console.log(props.id, props.color, "id color")

// const product = reactive({
//   imgUrls: [
//     'https://i.pinimg.com/originals/f9/8a/36/f98a36ecad33d37ffc8a059f5c1b4dc0.jpg',
//     'https://wallbox.ru/resize/1280x800/wallpapers/main/201303/55a739dd670b8bb.jpg',
//     'https://i.pinimg.com/originals/f9/8a/36/f98a36ecad33d37ffc8a059f5c1b4dc0.jpg',
//     'https://wallbox.ru/resize/1280x800/wallpapers/main/201303/55a739dd670b8bb.jpg'
//   ]
// })

const productRef = ref(null)

productService.GetProduct(props.id).then(product => {
  productRef.value = product
  console.log("product - ", product)


  const imgUrls = productRef.value.colorVariants.find(x=>x.color === props.color)?.coloredImgUrls

  productRef.value = Object.assign(productRef.value, {imgUrls: imgUrls})

})


</script>

<style scoped>

</style>