import httpClient from "@/httpClient/httpClient";
import productMapper from "@/mappers/productMapper";


export default {

    GetFavouriteProducts: async () => {
        let res = await httpClient.Get('/FavoriteItems/getFavoriteItems')

        return productMapper.mapAll(res.items)
    },

    AddToFavourite: async (id, color) => {
        await httpClient.Post("/FavoriteItems/addItem?id=" + id + "&color=" + color, {})
    },

    RemoveFromFavourite: async (id, color) => {
        await httpClient.Post("/FavoriteItems/removeItem?id=" + id + "&color=" + color, {})
    },


    GetFavouriteGroups: async () => {
        let res = await httpClient.Get('/FavoriteGroups/getGroupsWithItems')

        return res.groups
    },

    CreateGroup: async (name) => {
        return await httpClient.Post('/FavoriteGroups/addGroup', {name: name})
    },

    DeleteGroup: async (id) => {
        return await httpClient.Post('/FavoriteGroups/deleteGroup', {id: id})
    },

    AddToGroup: async (groupId, productId, color) => {
        return await httpClient.Post('/FavoriteGroups/addItemToGroup', {
            itemId: productId,
            color: color,
            groupId: groupId
        })
    },

    RemoveFromGroup: async (groupId, productId, color) => {
        return await httpClient.Post('/FavoriteGroups/deleteItemFromGroup', {
            itemId: productId,
            color: color,
            groupId: groupId
        })
    },

    GetUngrouppedPlusThisGroup: async (groupId) => {
        let res = await httpClient.Post('/FavoriteGroups/getItemsWithGroupMembership?groupId=' + groupId, {})

        return res.items
    }


}