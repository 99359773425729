<template>
  <HeaderWithBackIconElement back-link="/" heading="Мои заказы"/>
  <div class="sections">
    <div style="display: flex;">
      <div  style="margin-right: 15px;">
        <router-link to="/profile/orders">
          Заказы
        </router-link>
      </div>
      <div class="underlined">
        <router-link to="/profile/order_returns">
          Возвраты
        </router-link>
      </div>
    </div>

  </div>
  <OrdersComponent :orders="orders_examples"/>
</template>

<script setup>
import OrdersComponent from "@/components/Profile/OrdersComponent";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import exampleTestModels from "@/test/exampleTestModels";

const order_example = {
  type: 'delivery',
  number: '14578224',
  date: '12.06.2022',
  status: 'в обработке',
  cost: 7654,
  address: 'ул. грибоедова д. 3',
  times: '12:00 - 16:00',
  items: exampleTestModels.products,

}

const order_example2 = Object.assign(Object.assign({}, order_example), {type: 'consultation'})

const orders_examples = [
  order_example,
  order_example2
]

</script>

<style scoped>

a {
  text-decoration: none;
  color: #000000;
}

.underlined {
  display: inline-block;
  text-decoration: none;
  background-image: linear-gradient(to right, #9EADC4, #9EADC4);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size .5s ease;

  height: 25px;
}


.sections {
  margin: auto;
  margin-top: 10px;
  display: flex;

  justify-content: space-around;
}
</style>