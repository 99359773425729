<template>
  <div>
    <HeaderWithBackIconElement back-link="/basket/order/pickup" heading="Самовывоз"/>

    <div class="form-container">
      <InputElement :icon-clicked="()=>setGeoPoint()"
                    type="iconized" class="input"
                    v-model="form.address" label="Адрес магазина"/>

      <div style="margin-top: 10px;margin-bottom: 10px;">
        <div v-for="(market, i) in marketList" :key="i">
          <div class="address" @click="()=>selectMarket(market)">
            {{ market.address }}
          </div>
        </div>
      </div>

      <YMapElement ref="ymap"/>


      <div class="under-heading">
        контактные данные
      </div>
      <InputElement class="input" v-model="form.email" label="Email"/>
      <InputElement class="input" v-model="form.firstName" label="Имя"/>
      <InputElement class="input" v-model="form.lastName" label="Фамилия"/>

      <TextAreaElement width="100%" v-model="form.comment" label="Комментарии"/>


      <div class="flex app-grey-text">
        <div>
          Общий вес
        </div>
        <div class="right-text">
          {{ store.state.orderDetails.summaryWeight }} кг
        </div>
      </div>

      <div class="flex app-bold-text">
        <div>
          Стоимость к оплате
        </div>
        <div class="right-text">
          {{ store.state.orderDetails.summaryCost }} ₽
        </div>
      </div>

      <button @click="goToPayment" class="standart-size-btn app-blue-btn">
        Перейти к оплате
      </button>

    </div>


  </div>
</template>

<script setup>
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import {reactive, ref} from "vue";
import TextAreaElement from "@/elements/Inputs/TextAreaElement";
import store from "@/store";
import router from "@/router";
import YMapElement from "@/elements/YMapElement";

const ymap = ref(null)

const selectedMarket = reactive({coordinates: [0, 0], address: ''})

const selectMarket = (market) => {
  console.log(market)
  form.address = market.address
  selectedMarket.coordinates = market.coordinates
}

const setGeoPoint = () => {
  console.log(selectedMarket)
  ymap.value.SetGeoPoint(selectedMarket.coordinates)

}


const form = reactive({
  address: '',
  email: '',
  firstName: '',
  lastName: '',
  comment: ''
})

const goToPayment = () => {
  // TODO: do actions
  router.push('/payment/pickup')
}


const marketList = reactive([
  {
    address: 'sasl 2',
    coordinates: [1, 1]
  },
  {
    address: 'dsasl 1',
    coordinates: [10, 10]
  },
  {
    address: 'mosc',
    coordinates: [55.7, 37.6]
  },
])

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.address {

  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 5px;
  margin-left: 2px;
}

.input {
  margin-bottom: 7px;
  margin-top: 7px;
}

.form-container {
  padding: 10px;
}

.under-heading {

  color: #757577;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

}

</style>