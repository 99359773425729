<template>
  <div :style="'--width:'+width+';--height:'+height+';' ">
    <label for="input" class="input-label">
      <div>
        {{ props.label }}
      </div>
    </label>
    <textarea id="input" class="text-area"/>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";

const props = defineProps(['modelValue', 'label', 'width', 'height'])
defineEmits(['update:modelValue'])

const width = ref(props.width)
const height = ref(props.height)

if (width.value === null || width.value === undefined) {
  width.value = '200px'
}

if (height.value === null || height.value === undefined) {
  height.value = '100px'
}

</script>

<style scoped>


.input-label{
  height: 13px;
  top: 3.5px;
  font-family: Open Sans,serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #757577;
}

.text-area {
  width: var(--width);
  height: var(--height);
  resize: none;
}

</style>