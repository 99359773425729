<template>
  <div>
    <div class="header">
      <h3 style="margin: auto;padding-left: 26px;">
        Избранное
      </h3>
      <div @click="openAddCategoryDialog" style="margin-right: 10px;">
        <img src="/icons/plus.svg"/>
      </div>
    </div>
    <div class="sections">
      <div style="display: flex;">
        <div style="margin-right: 15px;">
          <router-link to="/favourites">
            Все товары
          </router-link>
        </div>
        <div class="underlined">
          <router-link to="/favourites/categories">
            Категории
          </router-link>
        </div>
      </div>

    </div>

    <div v-for="(group, i) in groups" :key="i">
      <FavouriteCategoryElement :delete-click="()=>{ openDeleteDialog(group.groupId) } " :group="group" />
    </div>

    <ModalWindowElement
        v-if="showDialog"
        :width="300"
        :height="150"
        :close="closeDialog">
      <div class="popup-container">
        <div class="popup-header">
          СОЗДАНИЕ КАТЕГОРИИ
        </div>
        <div class="category-input">
          <input v-model="categoryName" placeholder="Название категории" class="category-input-field"/>
        </div>
        <button @click="createCategory" class="app-blue-btn create-category-btn">
          Создать категорию
        </button>
      </div>

    </ModalWindowElement>

    <ModalWindowElement
        v-if="showSureDialog"
        :width="300"
        :height="150"
        :close="closeSureDialog">
      <div class="popup-container">
        <div>
          <img src="/icons/trash_can.svg"/>
        </div>
        <div>
          Вы уверены, что хотите удалить категорию
          <span>
            {{ deletingCategoryName }}
          </span>
          ?
        </div>
        <div class="popup-btns-container">
          <div class="app-blue-btn popup-btn" style="margin-right: 5px;" @click="closeSureDialog">
            нет
          </div>
          <div class="app-white-btn popup-btn" @click="deleteCategory">
            Да
          </div>

        </div>
      </div>

    </ModalWindowElement>

  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import FavouriteCategoryElement from "@/elements/Cards/FavouriteCategoryElement";
import ModalWindowElement from "@/elements/Panels/ModalWindowElement";
import favouritesService from "@/services/favouritesService";

//TODO: with delete category need to nullize categories in models in store + add api request

const props = defineProps(['groups'])

const groups = ref(props.groups)

const showDialog = ref(false)
const showSureDialog = ref(false)

const categoryName = ref('')
const deletingCategoryName = ref('')
const deletingGroupId = ref(null)

const reload = () => {
  favouritesService.GetFavouriteGroups().then(data=>{
    console.log("fv groups data loaded - ", data)
    groups.value = data
  })
}

const createCategory = () => {

  favouritesService.CreateGroup(categoryName.value).then(res => {
    console.log("creation category res - ", res)

    reload()
  })

  categoryName.value = ''
  showDialog.value = false
}


const openDeleteDialog = (groupId) => {
  deletingGroupId.value = groupId
  showSureDialog.value = true
}

const deleteCategory = () => {

  favouritesService.DeleteGroup(deletingGroupId.value).then(res => {
    console.log('DeleteGroup - ', res)

    reload()
  })


  closeSureDialog()
}

const closeDialog = () => {
  showDialog.value = false
}

const closeSureDialog = () => {
  showSureDialog.value = false
}


const openAddCategoryDialog = () => {
  showDialog.value = true
}


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.popup-btns-container {
  display: flex;
}

.popup-header {
  text-align: left;
  margin-bottom: 10px;
}

.create-category-btn {
  width: 100%;
  height: 40px;
}

.popup-container {
  padding: 10px;
}

.category-input {
  border: 1px solid #757577;
  padding: 10px;
}

.category-input-field {
  border: none;
  outline: none;
  width: 100%;
}


.header {
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: #000000;
}

.underlined {
  display: inline-block;
  text-decoration: none;
  background-image: linear-gradient(to right, #9EADC4, #9EADC4);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size .5s ease;

  height: 25px;
}


.sections {
  margin: auto;
  margin-top: 10px;
  display: flex;

  justify-content: space-around;

  margin-bottom: 10px;
}
</style>