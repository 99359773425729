import httpClient from "@/httpClient/httpClient";

export default {

    CreateConsultationPurchase: async (data)=>{
        return await httpClient.Post("/Purchase/CreateConsultationPurchase",data)
    },

    CheckPurchaseStatus: async (idempotenceKey)=>{
        return await httpClient.Get("/Purchase/Status?idempotenceKey="+idempotenceKey)
    },

}