import {createStore} from 'vuex'

export default createStore({
    state: {
        user: {
            loaded: false,
            email: '',
            code: '',
            token: '',
            isDesigner: false
        },
        config: {
            isMobile: false,
            container_size: 0
        },
        basket: [],
        favourite: [],
        favouriteCategoryNames: [],
        sections: {
            chairs: 'Стулья',
            tables: 'Столы',
            floors: 'Полы',
            walls: 'Стены',
            lighting: 'Освещение',
            soft_furniture: 'Мягкая мебель',
            storages: 'Хранилище'
        },

        default_search_filters: {

            catalogCategoryId: 0,
            // price order
            order_by_asc: true,
            // loft minimalism hai-tek
            style: {
                minimalism: false,
                loft: false,
                hai_tek: false
            },
            color: {
                red: false,
                yellow: false,
                grey: false,
                black: false,
                blue: false,
                turquoise: false,
            },
            minPrice: 1990,
            maxPrice: 204000,
            minWidth: 35,
            maxWidth: 3500,
            minDepth: 45,
            maxDepth: 1500,
            minHeight: 15,
            maxHeight: 2670,
            minWeight: 1,
            maxWeight: 67,
            searchString: ""
        },
        search_filters: {

            catalogCategoryId: 0,
            // price order
            order_by_asc: true,
            // loft minimalism hai-tek
            style: {
                minimalism: false,
                loft: false,
                hai_tek: false
            },
            color: {
                red: false,
                yellow: false,
                grey: false,
                black: false,
                blue: false,
                turquoise: false,
            },
            minPrice: 1990,
            maxPrice: 204000,
            minWidth: 35,
            maxWidth: 3500,
            minDepth: 45,
            maxDepth: 1500,
            minHeight: 15,
            maxHeight: 2670,
            minWeight: 1,
            maxWeight: 67,
            searchString: ""
        },

        previousPageData: {
            url: '',
            data: {}
        },

        orderDetails: {
            // puckup/deliver
            type: '',
            adress: '',
            email: '',
            firstName: '',
            lastName: '',
            comment: '',
            summaryWeight: 0,
            summaryCost: 0,
            purchaseCost: 0,
            shippingCost: 0,
        },

        orderNumber: 0,


    },
    getters: {
        getUser(state) {
            if (!state.user.loaded) {
                state.user = JSON.parse(localStorage.getItem('user'))
                state.user.loaded = true
            }

            return state.user
        }
    },
    mutations: {

        setOrderNumber(state, number) {
            this.state.orderNumber = number
        },

        updateOrderDetails(state, orderDetails) {
            state.orderDetails = Object.assign(state.orderDetails, orderDetails)
        },

        setBasket(state, products) {
            state.basket = products
        },

        deleteCategoryInFavourite(state, category) {
            for (let i = 0; i < state.favourite.length; i++) {
                if (state.favourite[i].userCategory === category) {
                    state.favourite[i].userCategory = ''
                }
            }
        },

        setFavouriteProducts(state, products) {
            state.favourite = products
        },

        updateFavouriteCategories(state, [productIds, category]) {
            console.log('updateFavouriteCategories')
            console.log(productIds)
            console.log(category)
            for (let i = 0; i < state.favourite.length; i++) {

                if (productIds.includes(state.favourite[i].id)) {
                    state.favourite[i].userCategory = category
                } else {
                    if (state.favourite[i].userCategory === category) {
                        state.favourite[i].userCategory = ''
                    }
                }
            }
        },

        setPreviousPageData(state, url, data) {
            state.previousPageData.url = url
            state.previousPageData.data = data
        },
        updateFilters(state, filters) {
            state.search_filters = filters
        },
        updateProductFilters(state, filters) {
            state.search_filters = Object.assign(state.search_filters, filters)
        },
        clearFavourite(state) {
            state.favourite.splice(0)
        },
        removeFromFavourite(state, productId) {
            let position = -1
            for (let i = 0; i < state.favourite.length; i++) {
                if (state.favourite[i].id === productId) {
                    position = i
                    break
                }
            }

            if (position !== -1) {
                state.favourite.splice(position, 1)
            } else {
                console.log('element not found error!!!')
            }
        },
        addToFavourite(state, product) {
            state.favourite.push(product)
        },
        clearBasket(state) {
            state.basket.splice(0)
        },
        removeFromBasket(state, productId) {
            console.log(productId)
            for (let i = 0; i < state.basket.length; i++) {
                if (state.basket[i].id === productId) {
                    console.log(state.basket[i])
                    state.basket.splice(i, 1)
                    console.log(state.basket)
                    return
                }
            }

            console.log('element not found !!!')
        },
        // also used to update count + or -
        addToBasket(state, product) {

            for (let i = 0; i < state.basket.length; i++) {
                if (state.basket[i].id === product.id) {
                    state.basket[i].addingCount = product.addingCount
                    return
                }
            }

            state.basket.push(product)
        },
        setEmail(state, email) {
            state.user.email = email;
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setCode(state, code) {
            state.user.code = code;
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setIsMobile(state, isMobile) {
            state.config.isMobile = isMobile;
        },
        setToken(state, token) {
            state.user.token = token
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setIsDesigner(state, isDesigner) {
            state.user.isDesigner = isDesigner
        }
    },
    actions: {
        updateBasket({commit}, product) {
            if (product.addingCount === 0) {
                commit('removeFromBasket', product.id)

            } else {
                commit('addToBasket', product)
            }
        }
    },
    modules: {}
})
