import httpClient from "@/httpClient/httpClient";

export default {

    GetForDesigner: async ()=>{
        return await httpClient.Post('/Consultation/getForDesigner', {})
    },

    GetForUser: async ()=>{
        return await httpClient.Post('/Consultation/getForUser', {})
    },

}