import httpClient from "@/httpClient/httpClient";

export default {

    SendMsg: async (data)=>{
      return await httpClient.Post("/Chat/addMessage",data)
    },

    GetHistory: async (memberId)=>{
        return await httpClient.Get("/Chat/getHistory?memberId="+memberId)
    },

}