<template>
  <div class="carousel-container" :style="'--width: '+width+'px;--height: '+height+'px;'">

    <div class="navigation-wrapper">
      <div ref="container" class="keen-slider">
        <div v-for="(url, i) in imgUrls" :key="i" class="keen-slider__slide custom-slide">
          <img :src="url" class="custom-slide"   />
        </div>
      </div>
      <svg
          v-if="useArrows"
          @click="slider.prev()"
          :class="{
          arrow: true,
          'arrow--left': true,
          'arrow--disabled': current === 0,
        }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
      >
        <path
            d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
        ></path>
      </svg>
      <svg
          v-if="slider && useArrows"
          @click="slider.next()"
          :class="{
          arrow: true,
          'arrow--right': true,
          'arrow--disabled': current === slider.track.details.slides.length - 1,
        }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
      </svg>
    </div>
    <div v-if="slider" class="dots">
      <button
          v-for="(_slide, idx) in dotHelper"
          @click="slider.moveToIdx(idx)"
          :class="{ dot: true, active: current === idx }"
          :key="idx"
      ></button>
    </div>
  </div>
</template>

<script setup>
import {computed, reactive, ref, defineProps} from 'vue'
import {useKeenSlider} from 'keen-slider/vue.es'
import 'keen-slider/keen-slider.min.css'


const props = defineProps(['imgUrls', 'width', 'height'])

const width = ref(props.width)
const height = ref(props.height)


if(props.width===undefined){
  width.value = 165
}

if(props.height===undefined){
  height.value = 165
}

const imgUrls = reactive([
  'https://i.pinimg.com/originals/f9/8a/36/f98a36ecad33d37ffc8a059f5c1b4dc0.jpg',
  'https://wallbox.ru/resize/1280x800/wallpapers/main/201303/55a739dd670b8bb.jpg'
])


if (props.imgUrls !== undefined && props.imgUrls !== null && props.imgUrls.length !== 0) {
  imgUrls.splice(0, 2)

  for (let i = 0; i < props.imgUrls.length; i++) {
    imgUrls.push(props.imgUrls[i])
  }

}

const current = ref(1)
const [container, slider] = useKeenSlider({
  initial: current.value,
  slideChanged: (s) => {
    current.value = s.track.details.rel
  },
})

const dotHelper = computed(() => slider.value ? [...Array(slider.value.track.details.slides.length).keys()] : [])



const useArrows = ref(false)


//const dotsWidth = ref( 'width: ' + (Math.round( 160/slidesNumber )) + 'px;' )


</script>

<style scoped>

:root{
  --width: 165px;
  --height: 165px;
  --container-height: calc(var(--height) + 20px)
}

.carousel-container {
  width: var(--width);
  height: var(--container-height)
}

.custom-slide {
  width: var(--width);
  height: var(--height);
}


[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
}

.number-slide1 {
  background: rgb(64, 175, 255);
  background: linear-gradient(
      128deg,
      rgba(64, 175, 255, 1) 0%,
      rgba(63, 97, 255, 1) 100%
  );
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(
      128deg,
      rgba(255, 154, 63, 1) 0%,
      rgba(255, 75, 64, 1) 100%
  );
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(
      128deg,
      rgba(182, 255, 64, 1) 0%,
      rgba(63, 255, 71, 1) 100%
  );
  background: linear-gradient(
      128deg,
      rgba(189, 255, 83, 1) 0%,
      rgba(43, 250, 82, 1) 100%
  );
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(
      128deg,
      rgba(64, 255, 242, 1) 0%,
      rgba(63, 188, 255, 1) 100%
  );
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(
      128deg,
      rgba(255, 64, 156, 1) 0%,
      rgba(255, 63, 63, 1) 100%
  );
}

.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(
      128deg,
      rgba(64, 76, 255, 1) 0%,
      rgba(174, 63, 255, 1) 100%
  );
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 100%;
  height: 10px;
  background: #c5c5c5;
  border-radius: 2px;
  margin: 0 2px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
</style>