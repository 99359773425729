<template>
  <div>
    <div class="header">
      <div class="img-container">
        <router-link to="/favourites/categories">
          <img src="/icons/close.svg" />
        </router-link>
      </div>
      <div class="heading">
        Добавить в категорию
      </div>
    </div>
    <div v-for="(product, i) in products" :key="i">
      <ProductCheckBoxElement
          :value="product.inThisGroup"
          :change="change"
          :product="product"/>
    </div>

    <BottomFixedPanelElement :height="70">
      <button class="app-blue-btn apply-btn" @click="addToCategory">
        Добавить выбранное в категорию
      </button>
    </BottomFixedPanelElement>
  </div>
</template>


<script setup>
import {defineProps, reactive} from "vue";
import ProductCheckBoxElement from "@/elements/Cards/ProductCheckBoxElement";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement";
import router from "@/router";
import favouritesService from "@/services/favouritesService";


const props = defineProps(['groupId'])


const products = reactive([])

const selectedProducts = reactive([])


const reload = () => {
  favouritesService.GetUngrouppedPlusThisGroup(props.groupId).then(data=>{
    data.forEach(el=>{
      products.push(el)
    })
    products.map(x=>x.inThisGroup)
  })
}

reload()


const addToCategory = () => {

  products.forEach(product=>{
    if(product.inThisGroup){
      favouritesService.AddToGroup(props.groupId, product.id, product.currentColor).then(res=>{
        console.log(res)
      })
    }else{
      favouritesService.RemoveFromGroup(props.groupId, product.id, product.currentColor).then(res=>{
        console.log(res)
      })
    }
  })


  router.push('/favourites/categories')
}


const change = (val, id, color) => {

  console.log("change - ", val, id, color)

  if (val) {
    selectedProducts.push({id: id, color: color})

    products[products.findIndex(el=>el.id===id)].inThisGroup = val


  } else {

    products[products.findIndex(el=>el.id===id)].inThisGroup = val

    selectedProducts.splice(selectedProducts.findIndex(el=>el.id===id), 1)

  }

}

</script>

<style src="../../assets/styles/buttons.css"></style>
<style scoped>

.heading{
  font-family: Open Sans;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  margin: auto;
}

.header{
  display: flex;
  padding: 10px;
}


.apply-btn {
  margin: 15px auto;
  height: 40px;
  width: 345px;

}

</style>