<template>
  <div class="category-box">

    <div class="header">
      <router-link style="display: inherit;" :to="'/favourite/category/'+props.group.name">
        <h3 class="category-name">
          {{ props.group.name }}
        </h3>
        <div class="counter">
          {{ props.group.items.length }} товаров
        </div>
      </router-link>
      <div @click="trashCanClick" class="trash-can-btn">
        <img src="/icons/trash_can.svg"/>
      </div>
    </div>


    <!--    <div class="products-container">-->
    <!--      <div v-for="(product, i) in props.products" :key="i">-->
    <!--        <ProductCardElement :show-heart="false" :product="product"/>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="products-carousel-container">
      <ProductsCarouselElement :products="props.group.items" :show-hearts="false"/>
    </div>

    <button @click="openAddProductsToCategoryPage" class="app-white-btn add-products-btn">
      Добавить товар
    </button>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
//import ProductCardElement from "@/elements/ProductCardElement";
import router from "@/router";
import ProductsCarouselElement from "@/elements/Carousels/ProductsCarouselElement";
//import favouritesService from "@/services/favouritesService";

// name - category name
const props = defineProps(['group', 'deleteClick'])

const openAddProductsToCategoryPage = () => {
  router.push('/addingToCategory/' + props.group.groupId)
}

const trashCanClick = () => {
  if (props.deleteClick !== null && props.deleteClick !== undefined) {
    props.deleteClick(props.name)
  }
}




</script>


<style src="../../assets/styles/buttons.css"></style>
<style scoped>

a {
  text-decoration: none;
  color: black;
}

.products-carousel-container {
  margin-left: 10px;
}

.category-name {
  margin-top: 0;
  margin-right: 10px;
}

.add-products-btn {
  background-color: #FFFFFF;
  width: 90%;
  margin: 10px auto;
  height: 40px;
}

.trash-can-btn {
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.products-container {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 10px;
}


.counter {

  font-family: Open Sans, serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 1px;
}

.header {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
}

.category-box {

  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  margin-bottom: 5px;

  padding-top: 10px;
  padding-bottom: 10px;

}

</style>