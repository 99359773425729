<template>
  <div class="range-input">

    <div class="inputs-container">
      <div class="labeled-input-container">
        <span class="input-label">
          от
        </span>
        <input
            :value="rangeObj.values[0]"
            @input="e => changed( [e.target.value - 0, rangeObj.values[1]]) "
            class="labeled-input"/>
      </div>
      <div class="labeled-input-container" style="margin-left: 8px;">
        <span class="input-label">
          до
        </span>
        <input
            :value="rangeObj.values[1]"
            @input="e => changed( [rangeObj.values[0], e.target.value - 0])"
            class="labeled-input"/>
      </div>
    </div>
    <Slider
        :min="props.min"
        :max="props.max"
        :tooltips="false"
        :lazy="false"
        class="slider"
        @update="update"
        @change="changed"
        v-model="rangeObj.values"/>

  </div>
</template>

<script setup>
import {reactive, defineProps} from "vue";
import Slider from '@vueform/slider'

const rangeObj = reactive({
  values: [0, 100]
})

const props = defineProps({
  min: Number,
  max: Number,
  minValue: Number,
  maxValue: Number,
  changed: Function
})


if (props.min !== undefined && props.max !== undefined) {
  rangeObj.values = [props.min, props.max]
}

if(props.minValue!==undefined && props.maxValue !==undefined){
  rangeObj.values = [props.minValue, props.maxValue]
}

const update = (vals) => {
  rangeObj.values = vals
}

const changed = (vals) => {
  console.log(vals)
  rangeObj.values = vals;
  if(props.changed!==undefined){
    props.changed(vals)
  }

}

</script>

<style src="../../../node_modules/@vueform/slider/themes/default.css"></style>
<style scoped>

.range-input {
  margin: 5px auto 10px;
}

.input-label {
  color: #B0B0B0;
}

.labeled-input-container {
  height: 30px;
  border: 2px solid #9EADC4;
  padding-top: 10px;
  padding-left: 5px;

  width: 45%;
}

.labeled-input {
  border: none;

  font-family: 'Open Sans', serif;
  font-weight: 400;
  Size: 15px;
  Line-height: 20px;

  width: 100px;
}

.labeled-input:focus {
  border: none;
  outline: none;
}


.slider {
  --slider-connect-bg: #9EADC4;
  --slider-tooltip-bg: #9EADC4;
  --slider-handle-ring-color: #9EADC4;

  margin: auto;
  margin-top: 10px;
  width: 90%;

}

.inputs-container {
  display: flex;
  margin: auto;
  justify-content: center;
}

.heading-label {
  font-family: Open Sans, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  color: #757577;
}

</style>