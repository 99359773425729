<template>
  <div class="app-content-container padding10" style="height: calc(100vh - 140px); ">
    <div class="catalog-section-header">
      <div class="back-arrow">
        <router-link to="/catalog">
          <img src="/icons/left-arrow.svg"/>
        </router-link>
      </div>
      <div class="heading">
        {{ sectionDict[props.section].name }}
      </div>
      <div class="magnifier">
        <router-link to="/catalog_search">
          <img src="/icons/magnifier.svg"/>
        </router-link>
      </div>
    </div>
    <div class="under-header">

      <div class="settings-icon-container">
        <router-link :to="settings_link">
          <img src="/icons/settings.svg"/>
        </router-link>
      </div>
<!--      <div v-for="(item, i) in searchItems" :key="i" style="margin: 2px;">-->
<!--        <button-->
<!--            class="app-blue-btn"-->
<!--            v-if="item.value===true"-->
<!--            @click="()=>changeSearchItem(item.name)">-->
<!--          {{ item.text }} &nbsp;-->
<!--          <img src="/icons/close_circled.svg"/>-->
<!--        </button>-->
<!--        <button-->
<!--            class="app-white-btn"-->
<!--            v-else-->
<!--            @click="()=>changeSearchItem(item.name)">-->
<!--          {{ item.text }}-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <div class="cards-container">
      <div v-for="(product, i) in products" :key="i"
           :style="i%2===0 ? 'margin-right: auto;': 'margin-left: auto;' ">
        <ProductCardCatalogElement :product="product"/>
      </div>
    </div>

  </div>
</template>

<script async setup>
import {computed, defineProps, reactive, ref} from "vue";
import store from "@/store";
import ProductCardCatalogElement from '@/elements/Cards/ProductCardCatalogElement'
import productService from "@/services/productService";
import catalogService from "@/services/catalogService";

const props = defineProps(['section'])

store.commit('setPreviousPageData',
    '/catalog_section/' + props.section, undefined)

let catalogCategoryId = catalogService.catalogServiceNameDict[props.section]
store.commit("updateFilters", Object.assign(store.state.search_filters,
    {catalogCategoryId: catalogCategoryId}))


const searchFilters = computed(() => store.state.search_filters)

const settings_link = ref('/catalog_search_filters/' + props.section)

console.log("filters", searchFilters)

const sectionDict = {
  chairs: {name: "Стулья", id: 6},
  floors: {name: "Полы", id: 2},
  lighting: {name: "Освещение", id: 3},
  soft_furniture: {name: "Мягкая мебель", id: 4},
  tables: {name: "Столы", id: 5},
  walls: {name: "Стены", id: 1},
  storages: {name: "Хранилище", id: 7},
}


// const searchItems = reactive([
//   {id: 1, text: 'Диван', name: 'sofa', value: true},
//   {id: 2, text: 'Угловые диваны', name: 'triangle_sofa', value: false},
//   {id: 3, text: 'Кресла', name: 'chairs', value: false},
// ])


// const changeSearchItem = (itemName) => {
//   for (let i = 0; i < searchItems.length; i++) {
//     if (searchItems[i].name === itemName) {
//       if (searchItems[i].value) {
//         searchItems[i].value = false
//       } else {
//         searchItems[i].value = true
//       }
//       break;
//     }
//   }
// }


const products = reactive([])



let data = productService.GetProducts(store.state.search_filters).then((data) => {

  console.log(data);
  console.log("was");
  data.forEach(x => products.push(x))
  console.log(products)

});

console.log(data);


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.settings-icon-container {
  margin: auto 5px;
}

.under-header {
  display: flex;
  flex-wrap: wrap;
}

.magnifier {
  margin-right: 20px;
  margin-top: 10px;
}

.back-arrow {
  margin-left: 20px;
  margin-top: 10px;
}

.heading {
  font-family: Open Sans, serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  margin: auto;
}

.catalog-section-header {
  height: 44px;
  display: flex;
  padding-top: 10px;
}

</style>