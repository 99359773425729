<template>
  <div class="card-container">
    <ProductCardElement :show-heart="true" :product="product"/>
    <div class="bottom-card-panel">
      <button
          @click="addProduct"
          v-if="product.addingCount===0"
          class="app-blue-btn" style="width: 100%;height: 40px;">
        <img src="/icons/basket.svg"/>
        &nbsp; В корзину
      </button>
      <div class="adding-products" style="padding-top: 10px;" v-else>
        <div @click="removeProduct" class="control-icon">
          <img src="/icons/minus.svg"/>
        </div>
        <div class="counter">
          {{ product.addingCount }}
        </div>
        <div @click="addProduct" class="control-icon">
          <img v-if="product.addingCount < product.countAvailable" src="/icons/plus.svg"/>
          <img v-else src="/icons/plus_disabled.svg"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {defineProps, reactive} from "vue";
//import store from "@/store";
import ProductCardElement from '@/elements/Cards/ProductCardElement'
import basketService from "@/services/basketService";

const props = defineProps(['product'])

const product = reactive(props.product)


const addProduct = () => {

  if (product.addingCount < product.countAvailable) {
    product.addingCount += 1

    basketService.AddToBasket(product.id, product.currentColor).then(res=>{
      console.log('AddToBasket - ', res)
    })

    //store.dispatch('updateBasket', product)
  }
}

const removeProduct = () => {

  basketService.RemoveFromBasket(product.id, product.currentColor).then(res=>{
    console.log('RemoveFromBasket - ', res)
  })

  product.addingCount -= 1
  //store.dispatch('updateBasket', product)
}


</script>
<style src="../../assets/styles/all.css"></style>
<style scoped>

.bottom-card-panel {
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.counter {
  width: 40%;
}

.adding-products {
  display: flex;
  justify-content: center;
}

.card-container {
  width: 165px;
}

.control-icon {
  cursor: pointer;
}

</style>