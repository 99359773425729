<template>
  <div>
    <h2 class="heading">Регистрация</h2>

    <InputElement v-model="form.email" :label="'Email'" class="input-element"/>
    <InputElement
        class="input-element"
        v-model="form.password"
        :label="'Придумайте пароль'"
        type="password"/>
    <InputElement
        class="input-element"
        v-model="form.passwordRepeat"
        :label="'Повторите пароль'"
        type="password"/>


    <div class="designer-checkbox-element">
      <div style="margin: auto auto;">
        Хочу зарегистрироваться как дизайнер
      </div>
      <div style="width: 30px;margin: auto auto;">
        <input v-model="form.asDesigner" type="checkbox"/>
      </div>
    </div>

    <div class="user-agreement-text">
      Создавая аккаунт,
      Вы даете свое согласие на
      обработку персональных и принимаете
      условия
      <a href="#"> Политики конфиденциальности </a>
      и
      <a href="#"> Пользовательского соглашения</a>
    </div>

    <button @click="register" class="app-blue-btn auth-btn">
      Зарегистрироваться
    </button>


    <button class="app-white-btn auth-btn no-underline">
      <router-link to="/auth">Уже есть аккаунт</router-link>
    </button>

  </div>
</template>

<script setup>

import { reactive} from 'vue'
import InputElement from "@/elements/Inputs/InputElement";
import httpClient from "@/httpClient/httpClient";
import router from "@/router";

const form = reactive({
  email: '',
  password: '',
  passwordRepeat: '',
  asDesigner: false
})


const register = async ()=>{
    let formData = {
      email: form.email,
      password: form.password,
      name: 'string',
      type: form.asDesigner ? "Designer" : "Default",
    }

    console.log(1)
    let res = await httpClient.Post('/auth/register', formData);
    console.log(res);

    if(res.status==="UserExist"){
      alert("пользователь с таким Email уже существует, перейдите на окно входа")
    } else if (res.status.toLowerCase()==="ok"){
      router.push("/auth")
    }

}



</script>
<style src="@/assets/styles/buttons.css"></style>
<style scoped>


.heading{
  font-family: 'Montserrat',serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;

}

.no-underline a:active{
  text-decoration: none;
}

.no-underline a{
  text-decoration: none;
}

.user-agreement-text {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #757577;
}

.user-agreement-text a {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #757577;
}

.input-element {
  margin-bottom: 10px;
}

.designer-checkbox-element {
  display: flex;
  height: 45px;
  margin-bottom: 15px;
}


input {
  background: #9EADC4;
  height: 22.5px;
  width: 22.5px;
}

div {
  width: 350px;
  text-align: left;
}

h2 {
  text-align: center;
}

</style>