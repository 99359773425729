<template>
  <div>
    <label for="input" class="input-label">
      <div  v-if="props.errorText === undefined || props.errorText === ''">
        {{ props.label }}
      </div>
      <div v-else class="input-label-error-text">
        {{ props.errorText }}
      </div>
    </label>
    <div id="input" class="right-icon-input inputBarcodeField" :style="border">
      <input
          list="data-list"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :type="type"/>

      <div v-if="props.type === 'password'">
        <img class="password"
            v-if="type==='password'"
            @click="changeType"
            src="@/assets/img/eye.svg">
        <img
            class="password"
            v-else
            @click="changeType"
            src="@/assets/img/eye_closed.svg">
      </div>

      <div v-if="props.type === 'iconized'">
        <img style="margin-top: 7px;"
            @click="()=> props.iconClicked()"
            src="../../assets/img/profile/geo-point.svg" />
      </div>

      <div  v-if="props.type === 'dropdown'">
        <img class="drop-down-icon" src="/icons/down-arrow.svg"/>
      </div>

      <datalist

          v-if="props.dropList!==null&&props.dropList!==undefined"
          id="data-list">

        <option v-for="(item, i) in props.dropList"
                :key="i" :value="item"/>
      </datalist>

    </div>
  </div>
</template>

<script setup>
// types: password text dropdown
import {defineProps, defineEmits, ref, watch} from "vue";

const props = defineProps(['modelValue', 'label',
  'type', 'errorText', 'dropList', 'iconClicked'])
defineEmits(['update:modelValue'])

const border = ref('')
const type = ref('text')

if (props.errorText !== undefined && props.errorText !== '') {
  border.value = 'border-color: #F93939;'
}

// eslint-disable-next-line no-unused-vars
watch(() => props.errorText, async (newText, oldText) => {
  console.log('text changed')
  if (!(props.errorText === undefined || props.errorText === '')) {
    border.value = 'border-color: #F93939;'
  } else {
    border.value = ''
  }
})

console.log(props.errorText)


const changeType = () => {
  if (type.value === 'password') {
    type.value = 'text'
  } else {
    type.value = 'password'
  }
}



if (props.type === 'password') {
  type.value = 'password'
}

//TODO: with type password eye must be closed!!! ( see 05/profile/peronal data )
</script>



<style scoped>

/*input::-webkit-calendar-picker-indicator {*/
/*  display: none;*/
/*}*/
/*[list]::-webkit-calendar-picker-indicator { display: none; }*/
/*input::-webkit-calendar-picker-indicator {*/
/*  display: none;*/
/*  -webkit-appearance: none;*/
/*}*/

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}


.drop-down-icon{
  margin-top: 10px;
}


.input-label-error-text {
  color: #FE4747;
  text-align: right;
}

.input-label {
  display: flex;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.right-icon-input {
  border: 1px solid #C4C4C4;
  padding: 10px;
  display: flex;
}

#input {
  height: 40px;
  margin: 0;
  padding: 0 0 0 10px;
}

img.password {
  margin-top: 12px;
  margin-right: 5px;
}

img:hover {
  cursor: pointer;
}

div {
  text-align: left;
}

label {
  height: 13px;
  top: 3.5px;
  font-family: Open Sans, serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #757577;
}

input {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  border: none;
  width: 100%;
  letter-spacing: 0em;
  text-align: left;

}

input:focus {
  border: none;
  outline: none;
}


</style>