<template>
  <div>
    <div class="close-icon">
      <router-link :to="'/product/'+product.id+'/'+props.color">
        <img src="/icons/close.svg" />
      </router-link>
    </div>
    <div style="height: 80px;"></div>
    <ThumbnailedCarouselElement
        width="375px"
        height="200px"
        thumbnail-height="50px"
        thumbnail-width="100px"
        :img-urls="product.imgUrls" />
  </div>
</template>

<script setup>
import {ref,defineProps} from "vue";
import ThumbnailedCarouselElement from "@/elements/Carousels/ThumbnailedCarouselElement";

const props = defineProps(['product', "color"])

// eslint-disable-next-line no-unused-vars
const getImgUrls = ()=>{

}



const width = ref(window.screen.width)



console.log(props)
console.log(width)

</script>

<style scoped>

.close-icon{
  height: 30px;
  width: 40px;
  border-radius: 0px;

  /*box-shadow: 10px 5px 5px black;*/

  padding-top: 10px;

  margin-top: 10px;
  margin-left: 10px;


  box-shadow: 0px 4px 8px 0px #0000000F;

}

</style>