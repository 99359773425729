<template>
  <div>
    <FavouriteCategoryComponent :products="products" :category="props.category" />
  </div>
</template>

<script setup>
import {defineProps, reactive} from "vue";
import FavouriteCategoryComponent from "@/components/Favourite/FavouriteCategoryComponent";
import exampleTestModels from "@/test/exampleTestModels";

const props = defineProps(['category'])

const products = reactive(exampleTestModels.products)

</script>

<style scoped>

</style>