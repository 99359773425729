<template>
  <div ref="container" class="keen-slider first">
    <div
        v-for="(product, i) in props.products" :key="i"
        class="keen-slider__slide">
      <ProductCardElement :show-heart="showHearts" :product="product"/>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {useKeenSlider} from "keen-slider/vue.es";
import "keen-slider/keen-slider.min.css";
import ProductCardElement from "@/elements/Cards/ProductCardElement"

const [container] = useKeenSlider({
  loop: false,
  mode: "free",
  slides: {
    perView: 1.9,
    spacing: 1,
  },
  selector: '.first > .keen-slider__slide'
});

const props = defineProps(['products', 'showHearts'])

const showHearts = ref(true)

if (props.showHearts === false) {
  showHearts.value = false
}

</script>

<style scoped>

.first {

}


[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;


}

</style>