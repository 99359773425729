<template>
  <div>
    <BasketComponent v-if="products!==null" :products="products"/>
    <div v-else>
      пожалуйста подождите, идет загрузка...
    </div>
  </div>
  <BottomFixedPanelElement height="60">
    <BottomMenuElement active-tab-name="basket"/>
  </BottomFixedPanelElement>
</template>

<script setup>
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement"
import BottomMenuElement from "@/elements/BottomMenuElement"
import BasketComponent from "@/components/Basket/BasketComponent";
import {ref} from "vue";
// eslint-disable-next-line no-unused-vars
import productService from "@/services/productService";
import basketService from "@/services/basketService";
// import store from "@/store";
// import exampleTestModels from "@/test/exampleTestModels";


const products = ref(null)

basketService.GetBasketProducts().then(data => {
  console.log("data - ",data)
  products.value = data
})


//const products = [].concat(exampleTestModels.products)

// products.forEach(x=>x.addingCount+=1)
//
// store.commit('setBasket', exampleTestModels.products)


</script>

<style scoped>

</style>