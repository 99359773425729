<template>
  <div>
    <HeaderWithBackIconElement back-link="/" heading=" " icon-url="/icons/close.svg" />

    <ThumbnailedCarouselElement width="100%" height="430px" :img-urls="props.imgUrls" />

  </div>
</template>

<script setup>
import {defineProps} from "vue";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import ThumbnailedCarouselElement from "@/elements/Carousels/ThumbnailedCarouselElement";

const props = defineProps(['imgUrls'])

console.log(props)

</script>

<style scoped>

</style>