import store from "@/store";


export default {

    ObjectToQuery: (filters) => {
        return Object.keys(filters)
            .map(key => `${key}=${filters[key]}`)
            .join('&');
    },

    // SearchFiltersToQuery: (filters) => {
    //
    // },

    GetSearchFiltersQuery: () => {
        let filters = store.state.search_filters

        let query = ""

        Object.keys(filters).forEach(x => {
            if (x !== "style" && x !== "color" && x !== "catalogCategoryId") {
                query += x + "=" + filters[x] + "&"
            }

            if(x==="catalogCategoryId"&&filters.searchString===""){
                query += x + "=" + filters[x] + "&"
            }



        })

        let nameOfActiveStyle = Object.keys(filters.style)[Object.keys(filters.style).findIndex(x => filters.style[x])]
        let nameOfActiveColor = Object.keys(filters.color)[Object.keys(filters.color).findIndex(x => filters.color[x])]

        if (nameOfActiveStyle !== undefined && nameOfActiveColor !== undefined) {
            query += "style=" + nameOfActiveStyle + "&"
        } else if (nameOfActiveStyle !== undefined && nameOfActiveColor === undefined) {
            query += "style=" + nameOfActiveStyle
        }

        if (nameOfActiveColor !== undefined) {
            query += "color=" + nameOfActiveColor
        }

        console.log("ACTIVE CLR STL", nameOfActiveColor, nameOfActiveStyle)

        return query
    }

}