<template>
  <div :style="variables">
    <div ref="container" class="keen-slider">


      <div
          v-for="(url, i) in props.imgUrls" :key="i"

          class="keen-slider__slide">
        <img class="carousel-img" :src="url"/>
      </div>

    </div>
    <div ref="thumbnail" class="keen-slider thumbnail">

      <div
          v-for="(url, i) in props.imgUrls" :key="i"

          class="keen-slider__slide ">
        <img class="carousel-thumbnail" :src="url"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {useKeenSlider} from 'keen-slider/vue.es'
import 'keen-slider/keen-slider.min.css'


const props = defineProps(['imgUrls', 'width', 'height',
  'thumbnailWidth', 'thumbnailHeight'])

const width = ref(props.width)
const height = ref(props.height)

const thumbnailWidth = ref(props.thumbnailWidth)
const thumbnailHeight = ref(props.thumbnailHeight)

if (props.width === undefined) {
  width.value = '200px'
}

if (props.height === undefined) {
  height.value = '150px'
}

if (props.thumbnailWidth === undefined) {
  thumbnailWidth.value = '100px'
}

if (props.thumbnailHeight === undefined) {
  thumbnailHeight.value = '75px'
}

const variables = ref('--width: ' + width.value + ';--height: ' + height.value +
    ';--thumbnail-height: ' + thumbnailHeight.value +
    ';--thumbnail-width: ' + thumbnailWidth.value)


function ThumbnailPlugin(main) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active")
      })
    }

    function addActive(idx) {
      slider.slides[idx].classList.add("active")
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          main.value.moveToIdx(idx)
        })
      })
    }

    slider.on("created", () => {
      addActive(slider.track.details.rel)
      addClickEvents()
      main.value.on("animationStarted", () => {
        removeActive()
        const next = main.value.animator.targetIdx || 0
        //addActive(main.track.absToRel(next))
        slider.moveToIdx(next)
      })
    })
  }
}

const [container, slider] = useKeenSlider()
const [thumbnail] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(slider)])


</script>

<style scoped>


.carousel-img {
  width: var(--width);
  height: var(--height);
}

/*noinspection ALL*/
.carousel-thumbnail {
  width: var(--thumbnail-width);
  height: var(--thumbnail-height);
}


[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
  max-height: 100vh;
}

.number-slide1 {
  background: rgb(64, 175, 255);
  background: linear-gradient(
      128deg,
      rgba(64, 175, 255, 1) 0%,
      rgba(63, 97, 255, 1) 100%
  );
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(
      128deg,
      rgba(255, 154, 63, 1) 0%,
      rgba(255, 75, 64, 1) 100%
  );
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(
      128deg,
      rgba(182, 255, 64, 1) 0%,
      rgba(63, 255, 71, 1) 100%
  );
  background: linear-gradient(
      128deg,
      rgba(189, 255, 83, 1) 0%,
      rgba(43, 250, 82, 1) 100%
  );
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(
      128deg,
      rgba(64, 255, 242, 1) 0%,
      rgba(63, 188, 255, 1) 100%
  );
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(
      128deg,
      rgba(255, 64, 156, 1) 0%,
      rgba(255, 63, 63, 1) 100%
  );
}

.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(
      128deg,
      rgba(64, 76, 255, 1) 0%,
      rgba(174, 63, 255, 1) 100%
  );
}

.thumbnail .keen-slider__slide {
  font-size: 30px;
  margin-top: 10px;
  height: 100px;
}

.thumbnail .keen-slider__slide {
  cursor: pointer;
}

.thumbnail .keen-slider__slide.active {
  /*border: 2px dashed black;*/
}


</style>