export default {

    catalogServiceNameDict: {
        chairs: 2,
        floors: 6,
        lighting: 4,
        soft_furniture: 7,
        storages: 5,
        tables: 3,
        walls: 1,
    }





}