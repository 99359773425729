<template>
  <div class="container">
    <div class="center">
      <div style="display: flex;">
        <div class="back-arrow">
          <router-link to="/catalog">
            <div>
              <img src="/icons/left-arrow.svg"/>
            </div>
          </router-link>
        </div>


        <div class="catalog-search-container">

          <input v-model="searchString" ref="input" @change="searchProducts" class="catalog-search" placeholder="Поиск"/>
        </div>
      </div>

      <div v-if="products!==null" class="cards-container">
        <div v-for="(product, i) in products" :key="i"
             :style="i%2===0 ? 'margin-right: auto;': 'margin-left: auto;' ">
          <ProductCardCatalogElement :product="product"/>
        </div>
      </div>
      <div v-else>
        идет загрузка...
      </div>


    </div>

  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
//import {Product} from "@/models/Product";
import ProductCardCatalogElement from '@/elements/Cards/ProductCardCatalogElement'
import productService from "@/services/productService";
import store from "@/store";

//const productName = 'Lorem ipsum dolor sit amet, consectetur'
//
// const productList = [
//   new Product(1, productName, 123, 100, 1),
//   new Product(11, productName, 123, 100, 2),
//   new Product(12, productName, 123, 100, 4),
//   new Product(13, productName, 123, 100, 5),
//   new Product(122, productName, 123, 100, 12),
// ]

const products = ref(null)


const input = ref()

const searchString = ref("")

onMounted(() => {
  console.log(input.value)
  input.value.focus()
})


const searchProducts = () => {
  products.value = null

  store.commit('updateProductFilters', {searchString: searchString.value})

  productService.GetProducts().then(res => {
    console.log("GetProducts", res)
    products.value = res

    store.commit('updateProductFilters', {searchString: ""})
  })
}

searchProducts()

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.container{
  padding: 10px;
}

.back-arrow {
  width: 25px;
  padding-top: 20px;
  padding-left: 15px;
}

.catalog-search:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}

.catalog-search {
  padding-left: 10px;
}

</style>