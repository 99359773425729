<template>
  <div class="enter-btn">
    <router-link to="/auth">Войти</router-link>
  </div>
</template>

<script>

</script>

<style scoped>
.enter-btn a{
  text-decoration: none;
}

.enter-btn{
  padding-top: 10px;
  height: 30px;
  width: 75px;
  left: 0px;
  top: 0px;
  border-radius: 0px;

  border: 1px solid #9EADC4;

  font-family: Open Sans,serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;



}

.enter-btn:hover{
  cursor: pointer;
}
</style>