<template>
  <div class="auth-component-container">
    <h2 class="heading">
      Авторизация
    </h2>
    <div>
      <InputElement
          :error-text="emailErrText"
          class="email"
          v-model="form.email"
          :label="'Email'"/>
    </div>
    <div>
      <InputElement
          :error-text="passwordErrText"
          v-model="form.password"
          :label="'Пароль'"
          type="password"/>
    </div>
    <div class="forgot-text">
      <router-link to="/password_recovery">забыли пароль?</router-link>
    </div>

    <button @click="authorize" class="app-blue-btn auth-btn">
      Войти
    </button>


    <button class="app-white-btn auth-btn register-link">
      <router-link to="/register">зарегистрироваться</router-link>
    </button>

  </div>
</template>

<script setup>
import InputElement from "@/elements/Inputs/InputElement";
import {reactive, ref} from "vue";
import httpClient from "@/httpClient/httpClient";
import store from "@/store";
import {validateEmail} from "@/actions/emailActions";
import router from "@/router";
import designerService from "@/services/designerService";

const form = reactive({
  password: '',
  email: ''
})

const emailErrText = ref('')
const passwordErrText = ref('')


const authorize = async () => {

  form.email = form.email.trim()

  if(validateEmail(form.email, emailErrText)){
    let res = await httpClient.Post('/auth/login', form)

    if(res.status.toString().toLowerCase() === 'ok'){

      let isDesigner = await designerService.IsDesigner()

      store.commit('setToken', res.token)
      store.commit('setEmail', form.email)
      localStorage.setItem("token", res.token)
      localStorage.setItem("isDesigner", isDesigner)
      localStorage.setItem("email", form.email)
      await router.push("/catalog")
    }else if(res.status.toLowerCase() === "passwordincorrect"){
      passwordErrText.value = "Введен неверный пароль"
    }

  }

}

</script>

<style src="@/assets/styles/buttons.css"></style>
<style scoped>

.register-link a:active{
  text-decoration: none;
}

.register-link a{
  text-decoration: none;
}

.divider{
  height: 20px;
}

.heading{
  font-family: 'Montserrat',serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;

}

.email{
  margin-bottom: 10px;
}

.forgot-text{
  margin-top: 20px;
  margin-bottom: 40px;

  font-family: Open Sans,serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;

  text-decoration: none !important;
}

.auth-component-container{
  width: 350px;
  text-align: center;
}

</style>