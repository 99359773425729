<template>
<BasketOrderDeliveryComponent v-if="products!==null" :products="products"  />
  <div v-else>
    идет загрузка...
  </div>
</template>

<script setup>
import BasketOrderDeliveryComponent from "@/components/Basket/BasketOrderDeliveryComponent";
import {ref} from "vue";
import basketService from "@/services/basketService";

const products = ref(null)

basketService.GetBasketProducts().then(data => {
  console.log("data - ",data)
  products.value = data
})



</script>
<style scoped>

</style>