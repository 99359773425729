<template>
  <ChatComponent v-if="user!==null" :member-id="props.id" :username="user.email" />
  <div v-else>
    идет загрузка, пожалуйста подождите...
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import ChatComponent from "@/components/Profile/ChatComponent";
import userService from "@/services/userService";

const props = defineProps(["id"])

const user = ref(null)

userService.GetUserData().then(res=>{
  console.log("GetUserData", res)
  user.value = res
})

// const messages = [
//   {
//     type: 'text',
//     from: 'dave',
//     text: 'hi',
//     imgUrl: '',
//     timeStamp: '12:53'
//   },
//   {
//     type: 'text',
//     from: 'dave',
//     text: 'how are you?',
//     imgUrl: '',
//     timeStamp: '12:53'
//   },
//   {
//     type: 'text',
//     from: 'egor',
//     text: 'hi',
//     imgUrl: '',
//     timeStamp: '12:53'
//   },
//   {
//     type: 'text',
//     from: 'egor',
//     text: 'i am ok, look',
//     imgUrl: '',
//     timeStamp: '12:53'
//   },
//   {
//     timeStamp: '12:54',
//     type: 'img',
//     from: 'egor',
//     text: 'nothing',
//     imgUrl: 'https://avatars.mds.yandex.net/i?id=5711a278e9a41a5d988ef88aba33bae7-5221285-images-thumbs&n=13&exp=1',
//   },
// ]


</script>

<style scoped>

</style>