<template>
  <OrderProcessingComponent :id="props.orderId" />
  <BottomFixedPanelElement :height="60" >
    <BottomMenuElement />
  </BottomFixedPanelElement>
</template>

<script setup>
import OrderProcessingComponent from "@/components/Basket/OrderProcessingComponent";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement";
import BottomMenuElement from "@/elements/BottomMenuElement";
import {defineProps} from "vue";

const props = defineProps(['orderId'])

console.log(props)

</script>

<style scoped>

</style>