import httpClient from "@/httpClient/httpClient";

export default {

    GetAllDesigners: async () => {
        return await httpClient.Get("/Designer/getAllDesigners")
    },

    GetDesigner: async (designerId) => {
        return await httpClient.Get("/Designer/getDesigner?userId=" + designerId)
    },

    GetSelf: async () => {
        return await httpClient.Get("/Designer/getDataByToken")
    },

    IsDesigner: async () => {
        let res = await httpClient.Get("/Designer/isDesigner")
        return res.status.toLowerCase() === "yes"
    },

    AddWorkToProfile: async (img) => {
        let fileData = new FormData()
        fileData.append("uploadedFile", img)

        let fileUploadRes = await httpClient.UploadUserFile(fileData)

        console.log("fileUploadRes", fileUploadRes)

        return httpClient.Get("/Designer/addWorkId?workId=" + fileUploadRes.id)
    },

    SetData: async (data)=>{
        return httpClient.Post("/Designer/setData", data)
    }

}