import store from "@/store";

export default {
    uri: 'http://127.0.0.1:5000/',

    Get: async (path) => {
        let uri = 'https://api.neurodesigner.ru'
        return await fetch(uri + path, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'assetType': 'Win',
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'token': store.state.user.token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        })
            .then((response) => {
                return response.json();
            });
    },


    Post: async (path, data) => {
        let uri = 'https://api.neurodesigner.ru'

        const postData = async (url = '', data = {}) => {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'assetType': 'Win',
                    'Content-Type': 'application/json',
                    'token': store.state.user.token
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        }

        return await postData(uri + path, data)
    },

    UploadUserFile: async (form) => {
        const uri = 'https://api.neurodesigner.ru'
        const postData = async (url = '', data = {}) => {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'token': store.state.user.token
                },
                body: data
            })
            return response.json(); // parses JSON response into native JavaScript objects
        }

        return await postData(uri + "/UserFile/upload", form)
    }


}