<template>
  <div>
    <ProductComponent v-if="productRef!==null" :product="pass"/>
    <div v-else>
      please wait, content is loading...
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, computed, watch} from "vue";
import ProductComponent from "@/components/Catalog/ProductComponent";
//import {Product} from "@/models/Product";
import productService from "@/services/productService";

const props = defineProps(['id', 'color'])

const productRef = ref(null)

const loadProduct = () => {
  productRef.value = null
  productService.GetProduct(props.id).then(product => {

    for (let i = 0; i < product.colorVariants.length; i++) {
      let colorVariant = product.colorVariants[i]

      if (colorVariant.color === props.color) {
        product.currentColor = colorVariant.color
        product.imgUrls = colorVariant.coloredImgUrls
        product.addingCount = colorVariant.count

        break
      }
    }


    productRef.value = product
    console.log("product - ", product)
  })
}

watch(() => props.id, (id, oldId) => {
  console.log("id has changed in prodview", id, oldId)
  loadProduct()
})

console.log(props.id)

const pass = computed(() => productRef.value)

console.log(123, props.id)

loadProduct()

</script>

<style scoped>

</style>