<template>

  <DesignerWorksComponent :img-urls="imgUrls"/>

</template>

<script setup>
import {defineProps, reactive} from "vue";
import DesignerWorksComponent from "@/components/Profile/DesignerWorksComponent";

const props = defineProps(['id'])

const imgUrls =  reactive([
  'https://avatars.mds.yandex.net/i?id=2a0000017a02588cf842a3693090432436fa-4120709-images-thumbs&n=13&exp=1',
  'https://cstor.nn2.ru/forum/data/forum/images/2012-12/59432513-ptxy0ib_kvg.jpg',
  'https://data.whicdn.com/images/312072189/original.jpg',
  'https://avatars.mds.yandex.net/i?id=2a0000017a02588cf842a3693090432436fa-4120709-images-thumbs&n=13&exp=1',
  'https://cstor.nn2.ru/forum/data/forum/images/2012-12/59432513-ptxy0ib_kvg.jpg',
  'https://data.whicdn.com/images/312072189/original.jpg',
  'https://avatars.mds.yandex.net/i?id=2a0000017a02588cf842a3693090432436fa-4120709-images-thumbs&n=13&exp=1',
  'https://cstor.nn2.ru/forum/data/forum/images/2012-12/59432513-ptxy0ib_kvg.jpg',
  'https://data.whicdn.com/images/312072189/original.jpg',
])

console.log('designer works id', props.id, imgUrls)




</script>

<style scoped>

</style>