<template>
  <div>

    <ProductCardElement :show-heart="props.showHeart" :product="props.product"/>
    <div style="width: 165px;">
      <div
          v-if="product.addingCount===0"
          class="buttons-container">
        <button class="app-blue-btn bottom-btn" @click="addProductToBasket">
          <img src="/icons/basket.svg"/>
        </button>
        <div>

        </div>
        <button class="app-white-btn bottom-btn" style="border: none;">
          <img src="/icons/media2.svg"/>
        </button>
      </div>


      <div class="adding-products" v-else>
        <div @click="removeProductFromBasket" class="control-icon">
          <img src="/icons/minus.svg"/>
        </div>
        <div class="counter">
          {{ product.addingCount }}
        </div>
        <div @click="addProductToBasket" class="control-icon">
          <img v-if="product.addingCount <= product.countAvailable" src="/icons/plus.svg"/>
          <img v-else src="/icons/plus_disabled.svg"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import {defineProps, reactive} from "vue";
import ProductCardElement from "@/elements/Cards/ProductCardElement";
import store from "@/store";

const props = defineProps(['product', 'showHeart'])

const product = reactive(props.product)




const addProductToBasket = () => {
  if (product.addingCount !== product.countAvailable) {
    product.addingCount += 1
    store.dispatch('updateBasket', product)
  }
}

const removeProductFromBasket = () => {
  product.addingCount -= 1
  store.dispatch('updateBasket', product)
}


</script>


<style src="../../assets/styles/buttons.css"></style>
<style scoped>

.counter {
  width: 40%;
}

.control-icon {
  cursor: pointer;
}

.adding-products {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  margin-bottom: 20px;
}

.bottom-btn {
  width: 51px;
  height: 40px;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  width: 165px;
}

</style>