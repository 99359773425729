<template>

  <div v-if="products!==null">
    <FavouriteComponent :products="products" />
  </div>
  <div v-else>
    loading please wait...
  </div>


  <BottomFixedPanelElement height="60">
    <BottomMenuElement active-tab-name="heart"/>
  </BottomFixedPanelElement>
</template>

<script setup>
import {ref} from "vue";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement"
import BottomMenuElement from "@/elements/BottomMenuElement"
import FavouriteComponent from "@/components/Favourite/FavouriteComponent";
import store from "@/store";
import exampleTestModels from "@/test/exampleTestModels";
import favouritesService from "@/services/favouritesService";


store.commit('setFavouriteProducts',
    exampleTestModels.products)


const products = ref(null)

favouritesService.GetFavouriteProducts().then((data)=>{
  products.value = data
})





</script>

<style scoped>

</style>