<template>

  <router-view/>
</template>
<script setup>
import store from "@/store";
import exampleTestModels from "@/test/exampleTestModels";



//console.log("navigator.userAgentData.mobile", navigator.userAgentData.mobile)

//console.log(navigator.userAgentData.mobile)

console.log("tmeta", screen, window)

// function isMobile(){
//   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
//     return true
//   }else{
//     return false
//   }
// }



store.commit('setIsMobile', screen.width<700);

store.commit('setFavouriteProducts', exampleTestModels.products)

const products = [].concat(exampleTestModels.products)

products.forEach(x => x.addingCount += 1)

products[0].addingCount += 1

store.commit('setBasket', exampleTestModels.products)



store.commit("setToken", localStorage.getItem("token"))
store.commit("setIsDesigner", localStorage.getItem("isDesigner"))


</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
