<template>
  <div>
    <HeaderWithBackIconElement heading="Изменить пароль" back-link="/profile/personal"/>
    <div class="app-content-container">
      <InputElement v-model="form.oldPassword" class="margin" type="password" label="Текущий пароль"/>
      <InputElement v-model="form.newPassword" class="margin" type="password" label="Новый пароль"/>
      <InputElement v-model="form.newPassword2" class="margin" type="password" label="Повторите новый пароль"/>
      <button @click="changePassword" class="app-blue-btn standart-size-btn">
        Сохранить
      </button>
    </div>

  </div>
</template>

<script setup>
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import {reactive} from "vue";
import userService from "@/services/userService";

const form = reactive({
  login: "",
  oldPassword: "",
  newPassword: "",
  newPassword2: ""
})

form.login = localStorage.getItem("email")

const changePassword = () => {
  if(form.newPassword!==form.newPassword2){
    alert("пароли не совпадают!")
  }

  userService.ChangePassword(form).then(res=>{
    console.log("changing password res - ", res)
  })

}


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.margin {
  margin-bottom: 10px;
}

</style>