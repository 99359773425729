<template>
  <MessageDesignerComponent v-if="designers!==null" :designers="designers"/>
  <div v-else>
    идет загрузка, пожалуйста подождите ...
  </div>
</template>

<script setup>
import MessageDesignerComponent from "@/components/Profile/MessageDesignerComponent";
import {ref} from "vue";
import designerService from "@/services/designerService";

// const example_designer = {
//   avatarUrl: '/imgs/avatar.png',
//   fullName: 'Зубенко Михаил',
//   rate: 4.5,
//   experience: 3,
//   hourlyRate: 300,
//   canMessage: true,
//   userId: 'dkoofklk'
//
// }
//
// const example_designers = [
//     example_designer
// ]

const designers = ref(null)

designerService.GetAllDesigners().then(res => {
  console.log("GetAllDesigners", res)

  designers.value = res.designers
})

</script>

<style scoped>

</style>