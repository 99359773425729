
import httpClient from "@/httpClient/httpClient";

export default {

    GetUserData: async () => {
        return await httpClient.Get('/User/getUserData')
    },

    SetUserData: async (userData) => {
        return await httpClient.Post('/User/setUserData', userData)
    },

    ChangePassword: async (data) => {
        return await httpClient.Post('/User/changePassword', data)
    },

}