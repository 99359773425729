export default {

    map: (product) => {
        product.name = product.title
        product.addingCount = 0

        if (product.countAvailable === undefined) {
            product.countAvailable = 99
        }

        return product
    },

    mapAll: (products) => {

        const mapProduct = (product) => {
            product.name = product.title

            if (product.countAvailable === undefined) {
                product.countAvailable = 99
            }

            return product
        }

        return products.map(x => mapProduct(x))
    }

}