<template>
  <div>
    <CatalogSectionComponent :section="props.section" />
    <BottomFixedPanelElement height="60">
      <BottomMenuElement active-tab-name="catalog" />
    </BottomFixedPanelElement>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import CatalogSectionComponent from "@/components/Catalog/CatalogSectionComponent"
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement"
import BottomMenuElement from "@/elements/BottomMenuElement"

const props = defineProps(['section'])

console.log(props.section)

</script>

<style scoped>

</style>