<template>
  <div class="background" @click="props.close"
       :style="'width: '+documentWidth+'px;height: '+ documentHeight+'px;' ">
    <div onclick="event.stopPropagation()" class="modal-window"
         :style="'margin-top: '+(documentHeight/2-height/1.5)+'px;width: '+width+'px;height: '+height+'px;'">
        <slot></slot>
    </div>
  </div>
</template>

<script setup>
import {defineProps,ref} from "vue";

const props = defineProps(['close', 'height', 'width'])



const documentWidth = ref(document.body.clientWidth)
const documentHeight = ref(window.screen.height)
const height = ref(props.height)
const width = ref(props.width)

if(height.value===undefined||height.value===null){
  height.value=100
}

if(width.value===undefined||width.value===null){
  width.value=200
}


</script>

<style scoped>

.modal-window{
  width: 200px;
  height: 100px;
  justify-self: center;
  z-index: 9999999;
  margin: auto;

  background-color: #FFFFFF;
}

.background{
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  vertical-align: middle;
}

</style>