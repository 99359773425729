<template>
  <div class="container">
    <HeaderWithBackIconElement
        back-link="/order/pickup" heading="Оплата заказа"/>

    <InputElement :icon-clicked="()=>setGeoPoint()" type="iconized" v-model="form.address" label="Адрес магазина"/>

    <div style="margin-top: 10px;margin-bottom: 10px;">
      <div v-for="(market, i) in marketList" :key="i">
        <div class="address" @click="()=>selectMarket(market)">
          {{ market.address }}
        </div>
      </div>
    </div>

    <div>
      <iframe class="ymaps-window" id="map_frame"
              :src="ymap_frame_src"></iframe>
    </div>


    <div class="app-grey-text">
      СПОСОБ ОПЛАТЫ
    </div>

    <SelectableBtnGroupElement
        :buttons-data="buttonsData"
        :selected="'samsung'"
        :selected-changed="radioBtnChanged"/>

    <div class="flex app-bold-text">
      <div>
        Стоимость к оплате
      </div>
      <div class="right-text">
        {{ store.state.orderDetails.summaryCost }} ₽
      </div>
    </div>

    <div class="app-grey-text">
      Нажимая оплатить, Вы соглашаетесь с
      <router-link to="">
        договором оферты
      </router-link>

    </div>

    <button
        @click="pay"
        class="google-pay-btn standart-size-btn">
      <div style="margin: auto;">
        <img src="/icons/G-mark.svg"/>
        Pay
      </div>

    </button>

  </div>

</template>


<script setup>
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import {reactive} from "vue";
import store from "@/store";
import SelectableBtnGroupElement from "@/elements/Buttons/SelectableBtnGroupElement";


const selectedMarket = reactive({coordinates: [0, 0], address: ''})

const ymap_frame_src = window.location.origin + '/ymap_snippet.html'

const selectMarket = (market) => {
  console.log(market)
  form.address = market.address
  selectedMarket.coordinates = market.coordinates
}

const radioBtnChanged = (val) => {
  console.log(val)
}

const setGeoPoint = () => {
  let map_frame = document.getElementById('map_frame')
  console.log('testing')
  console.log(map_frame)
  let coordinatesStr = JSON.stringify(selectedMarket.coordinates)
  console.log(coordinatesStr)
  map_frame.contentWindow.postMessage('{"coordinates": ' + coordinatesStr + '}', "*")

}

const marketList = reactive([
  {
    address: 'sasl 2',
    coordinates: [1, 1]
  },
  {
    address: 'dsasl 1',
    coordinates: [10, 10]
  },
  {
    address: 'mosc',
    coordinates: [55.7, 37.6]
  },
])

const buttonsData = [
  {
    key: 'market',
    text: 'Оплатить в магазине',
    iconUrl: ''
  },
  {
    key: 'online',
    text: 'Оплатить картой онлайн',
    iconUrl: ''
  },
  {
    key: 'google',
    text: 'Google Pay',
    iconUrl: '/icons/google-pay-mark.svg'
  },
  {
    key: 'samsung',
    text: 'Samsung Pay',
    iconUrl: '/icons/samsung-pay-mark.svg'
  },
]

const form = reactive({
  email: '',
  address: ''
})

// pay types: market, online, google, samsung
// const payType = ref('google')
//
// const payTypes = reactive({
//   market: true,
//   online: false,
//   google: false,
//   samsung: false
// })

// const changePayType = (type) => {
//   payTypes[payType.value] = !payTypes[payType.value]
//   payTypes[type] = !payTypes[type]
//   payType.value = type
// }

const pay = () => {
  console.log('payed?')
}

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>


.address {

  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 5px;
  margin-left: 2px;
}

.ymaps-window {
  border: none;
  height: 260px;
  width: 100%;
}

.google-pay-btn {
  border: none;
  background-color: black;
  color: white;
}


a {
  color: inherit;
}

.container {
  padding: 10px;
}

</style>