<template>
  <DesignerComponent v-if="designer!==null" :designer="designer"/>
  <div v-else>
    идет загрузка...
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DesignerComponent from "@/components/Profile/DesignerComponent";
import designerService from "@/services/designerService";

const props = defineProps(['id'])

console.log('designer id: ', props.id)

// const designer_example = {
//   avatarUrl: 'https://lookw.ru/8/828/1476173418-111-2.jpg',
//   firstName: '',
//   lastName: '',
//   fullName: 'Zubenko Michael',
//   rate: 4.5,
//   experience: 6,
//   hourlyRate: 2000,
//   workImgUrls: [
//     'https://avatars.mds.yandex.net/i?id=44e0ddf285d4c06535d6d1b6fd8eff50-5590892-images-thumbs&n=13&exp=1',
//     'https://avatars.mds.yandex.net/i?id=2a0000017a06b3514961a0f37b3b76ebcf60-4843806-images-thumbs&ref=rim&n=33&w=423&h=206',
//     'https://lookw.ru/8/828/1476173418-111-2.jpg',
//     'https://avatars.mds.yandex.net/i?id=44e0ddf285d4c06535d6d1b6fd8eff50-5590892-images-thumbs&n=13&exp=1',
//     'https://avatars.mds.yandex.net/i?id=2a0000017a06b3514961a0f37b3b76ebcf60-4843806-images-thumbs&ref=rim&n=33&w=423&h=206',
//     'https://lookw.ru/8/828/1476173418-111-2.jpg',
//   ],
// }

const designer = ref(null)

designerService.GetDesigner(props.id).then(res=>{
  console.log("GetDesigner", res)
  designer.value = res.designer
})


</script>

<style scoped>

</style>