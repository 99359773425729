<template>
  <div class="container">
    <div class="inner-container">
      <div class="img-container" @click="goToProductPage">
        <img class="product-img" :src="product.imgUrls[0]"/>
      </div>
      <div class="content">
        <div class="header">
          <div>
            {{ product.name }}
          </div>
          <div
              v-if="showHeart"
              class="like-icon"
              @click="addRemoveFromFavourite">
            <img :src="inFavourites ? '/icons/heart_filled.svg' : '/icons/heart.svg' "/>
          </div>
        </div>
        <div class="parameters">
          <div>
            <img src="/icons/weight-icon.svg"/>
            {{ product.weight }} кг
          </div>
          <div v-if="true">
            {{ product.addingCount }} шт
          </div>
          <div>
            ШхВхГ(см): {{ product.width }}х{{ product.height }}х{{ product.deep }}
          </div>
        </div>
        <div class="footer">
          <div class="price">
            {{ product.price }} ₽
          </div>
          <div v-if="canChangeCount" class="adding-products">
            <div @click="removeProduct" class="control-icon">
              <img src="/icons/minus.svg"/>
            </div>
            <div class="counter">
              {{ product.addingCount }}
            </div>
            <div @click="addProduct" class="control-icon">
              <img v-if="product.addingCount <= product.countAvailable" src="/icons/plus.svg"/>
              <img v-else src="/icons/plus_disabled.svg"/>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, reactive} from "vue";
import store from "@/store";
import basketService from "@/services/basketService";
import router from "@/router";

const props = defineProps(['product', 'showHeart', 'canChangeCount'])

const product = reactive(props.product)

const showHeart = ref(true)

const canChangeCount = ref(false)

if (props.canChangeCount === true) {
  canChangeCount.value = true
}

const inFavourites = ref(props.product.liked)

// I just don't believe javascript
if (props.showHeart === false) {
  showHeart.value = false
}

const addProduct = () => {
  if (product.addingCount <= product.countAvailable) {

    basketService.AddToBasket(product.id, product.currentColor).then(res=>{
      console.log("AddToBasket", res)
    })

    product.addingCount += 1
    //store.dispatch('updateBasket', product)
  }
}

const removeProduct = () => {
  if (product.addingCount > 0) {

    basketService.RemoveFromBasket(product.id, product.currentColor).then(res=>{
      console.log("RemoveFromBasket", res)
    })

    product.addingCount -= 1
    //store.dispatch('updateBasket', product)
  }

}

const addRemoveFromFavourite = () => {
  if (inFavourites.value) {
    removeFromFavourite(props.product.id)
  } else {
    addToFavourite(props.product)
  }
}

const addToFavourite = () => {
  store.commit('addToFavourite', props.product)
  inFavourites.value = true
}

const removeFromFavourite = () => {
  store.commit('removeFromFavourite', props.product.id)
  inFavourites.value = false
}

const goToProductPage = () => {
  router.push("/product/"+product.id+"/"+product.currentColor)
}

</script>

<style scoped>

.counter {
  width: 40%;
}

.control-icon {
  cursor: pointer;
}

.adding-products {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  width: 90px;
}

.like-icon {
  margin-left: auto;
}

.content {
  width: 100%;
}

.inner-container {
  display: flex;
}


.product-img {
  width: 75px;
  height: 75px;
}

.footer {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.parameters {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  color: #757577;
}

.header {
  display: flex;
}

.img-container {
  display: flex;
  margin-right: 5px;
  cursor: pointer;
}

.container {
  padding: 5px;
}

</style>