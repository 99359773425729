<template>
  <div style="padding: 10px;">
    <HeaderWithBackIconElement heading="Оформление заказа" back-link="/basket"/>
    <div class="sections">
      <div style="display: flex;">
        <div class="underlined" style="margin-right: 15px;">
          <router-link to="/basket/order/pickup">
            Самовывоз
          </router-link>
        </div>
        <div>
          <router-link to="/basket/order/delivery">
            Доставка
          </router-link>
        </div>
      </div>

    </div>

    <div>
      <div v-for="(product, i) in products" :key="i">
        <ProductCardBasketElement :product="product" :can-change-count="false"/>
      </div>
    </div>


    <div class="weight-info">
      <div>
        Общий вес
      </div>
      <div class="right-element">
        {{ Math.round(summaryWeight * 10) / 10 }} кг
      </div>
    </div>
    <div class="cost-info">
      <div>
        Общая стоимость
      </div>
      <div class="right-element">
        {{  Math.round(summaryCost * 10) / 10}} ₽
      </div>
    </div>
    <button @click="goToOrder" class="app-blue-btn standart-size-btn">
      Оформить самовывоз
    </button>

  </div>
</template>

<script setup>
import store from "@/store";
import {computed} from "vue";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import ProductCardBasketElement from "@/elements/Cards/ProductCardBasketElement";
import router from "@/router";

const products = computed(() => store.state.basket)

const goToOrder = ()=>{
  router.push('/order/pickup')
}

const initialValue = 0;
const summaryWeight = products.value.map(x => x.weight).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
);

const summaryCost = products.value.map(x => x.price * x.addingCount).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
);

</script>

<style src="../../assets/styles/buttons.css"></style>
<style scoped>

.right-element {
  margin-left: auto;

}

.cost-info {
  display: flex;
  color: black;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.weight-info {
  display: flex;
  color: #757577;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}


a {
  text-decoration: none;
  color: #000000;
}

.underlined {
  display: inline-block;
  text-decoration: none;
  background-image: linear-gradient(to right, #9EADC4, #9EADC4);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size .5s ease;

  height: 25px;
}

.sections {
  margin: 10px auto auto;
  display: flex;

  justify-content: space-around;
}

</style>