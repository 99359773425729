<template>
  <DesignerPaymentComponent v-if="designer!==null" :designer="designer" />
  <div v-else>
    идет загрузка...
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DesignerPaymentComponent from "@/components/Profile/DesignerPaymentComponent";
import designerService from "@/services/designerService";

const props = defineProps(['id'])

const designer = ref(null)

designerService.GetDesigner(props.id).then(res=>{
  console.log("GetDesigner", res)
  designer.value = res.designer
})

console.log(props.id)

</script>

<style scoped>

</style>