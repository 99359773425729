<template>
  <div class="app-content-container">
    <div class="app-bold-text">
       Заказ № {{store.state.orderNumber}}
    </div>
    <div>
      Спасибо за заказ, он принят  в обработку.
      Все заказы и их статусы можно просмотреть в разделе
      Мои заказы в Профиле
    </div>

    <router-link to="/basket">
      <button class="app-white-btn standart-size-btn">
        Мои заказы
      </button>
    </router-link>

  </div>
</template>

<script setup>
import {defineProps} from "vue";
import store from "@/store";

const props = defineProps(["id"])

console.log(props)


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

a{
  outline: none;
  text-decoration: none;
}

</style>