<template>
  <div class="app-content-container">

    <HeaderWithBackIconElement style="margin-bottom: 15px;" back-link="/profile" heading="Выберите город" />

    <div v-for="(city, i) in shownCities" :key="i">
      <div class="flex item" @click="()=>{selectedCity = city}">
        <div>
          {{city}}
        </div>
        <div class="check-icon" v-if="selectedCity===city">
          <img src="../../assets/img/profile/checked.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps,ref, computed} from "vue";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";

const props = defineProps(['cities', 'selectedCity'])

const selectedCity = ref(props.selectedCity)

const search = ref('')

const shownCities = computed(()=>props.cities.filter(x=>x.includes(search.value)))



</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.item{
  margin-bottom: 10px;
  height: 30px;
}

.check-icon{
  margin-left: auto;
  margin-right: 10px;
}

</style>