<template>
  <div>
    <button
        v-for="(data, i) in props.buttonsData" :key="i"
        @click="()=>{selectedChanged(data.key)}"
        :class="(data.key === selected ? classTrue : classFalse) + ' standart-size-btn flex'"
    >

        <div class="middle-element">
          {{ data.text }}
        </div>
        <div v-if="data.iconUrl!==null&&data.iconUrl!==undefined&&data.iconUrl!==''"
             class="right-text middle-element">
          <img :src="data.iconUrl" />
        </div>

    </button>
  </div>
</template>

<script setup>
//TODO: maybe create not only radio type
import {ref, defineProps} from "vue";

// buttonsData: [ { key: '', text: '', iconUrl: '' } ]
const props = defineProps(['classTrue', 'classFalse',
  'buttonsData', 'selected', 'selectedChanged'])

const selected = ref(props.selected)

const selectedChanged = (val)=>{
  props.selectedChanged(val)
  selected.value = val
}



const classTrue = ref(props.classTrue)
const classFalse = ref(props.classFalse)


if(props.classFalse === undefined || props.classFalse === null){
  classFalse.value = 'defaultFalse'
}

if(props.classTrue === undefined || props.classTrue === null){
  classTrue.value = 'defaultTrue'
}


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.middle-element{
  margin-top: auto;
  margin-bottom: auto;
}

.defaultTrue {
  margin-top: 10px;
  margin-bottom: 10px;

  background-color: white;
  border: none;
  box-shadow: 0px 0px 4px 2px rgba(158, 173, 196, 1);
}

.defaultFalse {
  margin-top: 10px;
  margin-bottom: 10px;

  background-color: white;
  border: none;
  box-shadow: 0px 0px 4px 2px rgba(34, 60, 80, 0.2);

}

</style>