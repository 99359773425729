<template>
  <div class="app-content-container " style="height: calc( 100vh - 260px );">
    <div class="header">
      <div class="heading">
        Корзина
      </div>
      <div class="trash-can">
        <img @click="openClearBasketDialog" src="/icons/trash_can.svg"/>
      </div>
    </div>
    <div v-for="(product, i) in products" :key="i" style="margin-bottom: 5px;">
      <ProductCardBasketElement :can-change-count="true" :product="product"/>
    </div>

    <ModalWindowElement :height="190" :width="315" :close="closeClearBasketDialog" v-if="clearBasketState">
      <div style="color: #FFFFFF;">.</div>
      <div style="margin: 10px auto;">
        <img style="width: 41px;height: 45px;" src="/icons/trash_can.svg"/>
      </div>
      <div style="width: 280px;margin: auto;">
        Вы уверены что хотите очистить корзину?
      </div>
      <div class="bottom-buttons">
        <button
            @click="closeClearBasketDialog"
            class="app-blue-btn clear-dialog-btn"
            style="margin-right: 10px;">
          Нет
        </button>
        <button
            @click="clearBasket"
            class="app-white-btn clear-dialog-btn">
          Да
        </button>
      </div>
    </ModalWindowElement>

      <div class="order-window">
        <div class="weight-container">
          <div>
            Общий вес
          </div>
          <div class="right-text">
            {{ Math.round(sumWeight * 10) / 10 }} кг
          </div>
        </div>
        <div class="price-container">
          <div>
            Общая стоимость
          </div>
          <div class="right-text">
            {{ sumPrice }} ₽
          </div>
        </div>
        <button @click="makeOrder" class="app-blue-btn order-btn">
          Оформить заказ
        </button>
      </div>


  </div>
</template>

<script setup>
import {computed, ref, defineProps, reactive} from "vue";
import store from "@/store";
import ProductCardBasketElement from "@/elements/Cards/ProductCardBasketElement";
import ModalWindowElement from "@/elements/Panels/ModalWindowElement";
import router from "@/router";

const props = defineProps(["products"])

const products = reactive(props.products)

//const products = computed(() => store.state.basket)

const initialValue = 0
const sumPrice = computed(() => products.map(x => x.price).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
))

const sumWeight = computed(() => products.map(x => x.weight).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
))

const clearBasketState = ref(false)

const openClearBasketDialog = () => {
  clearBasketState.value = true
}

const clearBasket = () => {
  store.commit('setBasket', [])
  clearBasketState.value = false
}

const closeClearBasketDialog = () => {
  clearBasketState.value = false
}


const makeOrder = () => {
  router.push('/basket/order/delivery')
}


</script>

<style src="../../assets/styles/buttons.css"></style>
<style scoped>

.clear-dialog-btn {
  width: 138px;
  height: 40px;
}

.right-text {
  margin-left: auto;
}

.order-window {
  padding: 10px;
  margin: auto;
  position: fixed;
  /* top: calc(100vh - 120px); */
  top: calc( 100vh - 260px );
  width: calc( 100% - 20px );
}

.order-btn {
  height: 40px;
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

.weight-container {
  margin-bottom: 10px;

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  display: flex;
}

.price-container {
  display: flex;

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.bottom-buttons {
  display: flex;
  padding: 10px;
}

.trash-can {
  margin-top: 8px;
  margin-right: 10px;
}

.heading {
  margin: auto;

  font-family: 'Open Sans',serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  padding-top: 8px;
}

.header {
  display: flex;
  margin-bottom: 10px;
}

</style>